import { getStyle } from '@coreui/coreui/dist/js/coreui-utilities';
const brandPrimary = getStyle('--primary');
const initialState = {
    tasas: {},
    info_tasa: {
            'avg' : {
                avg_24h: 0
            },
            'alto' : 0,
            'bajo' : 0,
            'oferta' : 0,
            'demanda' : 0,
            'volume' : 0,
            'cambio' : 0,
            'valor' : 0,
            'cantidad' : 0,
            'rate_usd': 0,
            'h24' : 0,
            'date_graf': [ 25 ],
            'cant_graf': [ 25 ]
        },
    mainChart : {
        labels: [0],
        datasets: [{
                label: 'Bs.S./BTC ',
                backgroundColor: 'rgba(255,255,255, 0)',
                borderColor: '#20a8d8',
                pointHoverBackgroundColor: '#fff',
                borderWidth: 2,
                data:[0],
            }],
    },
    max: 0,
    min: 0
};

const tasaReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_TASAS':
            return {...state, tasas: [...action.tasas] };
        case 'FETCH_TASA':
            return {...state, info_tasa: action.info_tasa };
        case 'FETCH_GRAF':
            return {
                ...state, 
                max: Math.max(...action.data.cant_graf),
                min: Math.min(...action.data.cant_graf),
                mainChart:{
                    labels: action.data.date_graf,
                    datasets: [{
                            label: 'Bs.S./BTC ',
                            backgroundColor: 'rgba(255,255,255, 0)',
                            borderColor: '#20a8d8',
                            pointHoverBackgroundColor: '#fff',
                            borderWidth: 2,
                            data: action.data.cant_graf,
                        }],
                } 
            };
        default:
            return state;

    }
};

export default tasaReducer;