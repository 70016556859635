import React from 'react';
import Loadable from 'react-loadable'

function Loading() {
  return <div></div>;
}

const Dashboard = Loadable({
  loader: () => import('./views/Dashboard'),
  loading: Loading,
});

const DashboardCommerce = Loadable({
	loader: () => import('./views/Dashboard/DashboardCommerce'),
	loading: Loading,
});

const SimpleLineIcons = Loadable({
  loader: () => import('./views/Icons/SimpleLineIcons'),
  loading: Loading,
});


const Users = Loadable({
  loader: () => import('./views/Users/Users.jsx'),
  loading: Loading,
});

const User = Loadable({
  loader: () => import('./views/Users/CustomerProfile.jsx'),
  loading: Loading,
});

const CommerceSettings = Loadable({
	loader: () => import('./views/Commerce/Settings.jsx'),
	loading: Loading,
});

const PayRegistro = Loadable({
  loader: () => import('./views/PayRegistro/PayRegistro.js'),
  loading: Loading,
});

const Tasa = Loadable({
  loader: () => import('./views/Tasa/Tasa.js'),
  loading: Loading,
});


const Balance = Loadable({
  loader: () => import('./views/Balance/Balance'),
  loading: Loading,
});


const CreateWallet = Loadable({
  loader: () => import('./views/Wallet/CreateWallet'),
  loading: Loading,
});


const Wallet = Loadable({
  loader: () => import('./views/Wallet/Wallet'),
  loading: Loading,
});

const CommerceTable = Loadable({
	loader: () => import('./views/Commerce/CommerceTable'),
	loading: Loading,
});

const Bank = Loadable({
  loader: () => import('./views/Bank/Bank'),
  loading: Loading,
});

const CommerceBankAccounts = Loadable({
	loader: () => import('./views/CommerceBankAccounts/CommerceBankAccounts'),
	loading: Loading,
});

const UnvalidTransactions = Loadable({
  loader: () => import('./views/Balance/UnvalidTransactions'),
  loading: Loading,
});

const UnvalidTransactionsRegular = Loadable({
  loader: () => import('./views/Balance/UnvalidTransactionsRegular'),
  loading: Loading,
});

const CommerceDetali = Loadable({
  loader: () => import('./views/Commerce/Commerce.jsx'),
  loading: Loading,
});

const CommerceDetaliVerify = Loadable({
  loader: () => import('./views/Commerce/CommerceDetaliVerify.jsx'),
  loading: Loading,
});

const RegisterWithdraw = Loadable({
  loader: () => import('./views/Withdraw/RegisterWithdraw.jsx'),
  loading: Loading,
});

const NewRegisterWithdraw = Loadable({
  loader: () => import('./views/Withdraw/newRegisterWithdraw.jsx'),
  loading: Loading,
});

const ListWithdraw = Loadable({
  loader: () => import('./views/Withdraw/ListWithdraw.jsx'),
  loading: Loading,
});

const DetailWithdraw = Loadable({
  loader: () => import('./views/Withdraw/DetailWithdraw.jsx'),
  loading: Loading,
});

const BankAccount = Loadable({
  loader: () => import('./views/BankAccount/BankAccount.jsx'),
  loading: Loading,
});

let user_type = JSON.parse(sessionStorage.getItem("user")) && JSON.parse(sessionStorage.getItem("user")).type;

const routes = [
  { path: '/', exact: true, name: '', component: Tasa },
  { path: '/dashboard', name: '', component: user_type === 2 ? DashboardCommerce : Dashboard },
  { path: '/tasa', name: '', component: Tasa },
  { path: '/registrar-pago', name: '', component: PayRegistro },
  { path: '/perfil', exact: true, name: '', component: User },
  { path: '/comercio', exact: true, name: '', component: CommerceSettings },
  { path: '/balance', name: '', component: Balance },
  { path: '/tx-sin-validar', name: '', component: UnvalidTransactions },
  { path: '/tx-sin-validar-caja', name: '', component: UnvalidTransactionsRegular },
  { path: '/billeteras', name: '', component: Wallet },
  { path: '/comercios', name: '', component: CommerceTable },
  { path: '/nueva-billetera', name: '', component: CreateWallet },
  { path: '/usuarios/:id', exact: true, name: 'User Details', component: User },
  { path: '/usuarios', exact: true, name: 'Users', component: Users },
  { path: '/bancos', exact: true, name: 'Bank', component: Bank },
  { path: '/registrar-liquidacion', exact: true, name: 'RegisterWithdraw', component: RegisterWithdraw },
  { path: '/registrar-liquidacion-new', exact: true, name: 'RegisterWithdraw', component: NewRegisterWithdraw },
  { path: '/liquidaciones', exact: true, name: 'ListWithdraw', component: ListWithdraw },
  { path: '/cuentas-bancarias', exact: true, name: 'CommerceBankAccounts', component: CommerceBankAccounts },
  { path: '/icons/simple-line-icons', name: 'Simple Line Icons', component: SimpleLineIcons },
  { path: '/comercio/:id', name: '', component: CommerceDetali },
  { path: '/comercio-verify/:id', name: '', component: CommerceDetaliVerify },
  { path: '/liquidacion/:id', name: '', component: DetailWithdraw },
  { path: '/admin-cuentas-bancarias', name: '', component: BankAccount },
];

export default routes;
