import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Input, Row, Label, FormGroup } from 'reactstrap';
import { Notification, Preloader } from '../Plugins/';
import { passwordUpdate } from '../../store/actions/userActions';
import logo_login from '../../assets/img/brand/logo_login.png';
import { clear, has, get } from '../../store/actions/notificationsActions';

class NewPasswordForm extends Component {
	constructor(props){
		super(props);

		this.state = {
			email: '',
			password: '',
			errorEmail: '',
			errorPassword: '',
			token: this.props.match.params.token
		}
	}

	onChange = (target) => {
		const { name, value } = target;

		if(name === 'email'){
			let classError = '';
			if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test( value )){
				classError = 'form-control input-error';
			} else {
				classError = '';
			}
			this.setState(prevState => ({
				...prevState,
				errorEmail: classError
			}));
		}

		// terminar esto mañana
		if(name === 'password'){
			let classError = '';
			if( value.length < 6 ){
				classError = 'form-control input-error';
			} else {
				classError = '';
			}
			this.setState(prevState => ({
				...prevState,
				errorPassword: classError
			}));
		}

		this.setState(prevState => ({
			...prevState,
			[name]: value
		}));
	};

	render(){
		document.body.setAttribute("class", "democlass");

		return (
			<div className="app flex-row align-items-center">
					<Container>
						<Row className="justify-content-center">
							<Col md="5" sm="12" className="login">
								<Card className="p-4">
									{ (!this.state.token) ?
										<CardBody style={{"marginTop": "-100px"}}>
											<div className="text-center mb-2"><img src={logo_login} width="120" alt="logo"/></div>
											<hr className="mb-4" />
											<h1>¡Excelente!</h1>
											<p className="text-muted">Por favor escribe tu correo electrónico y tu nueva contraseña</p>
											<FormGroup>
												<Label>Correo electrónico</Label> { this.props.has('password') ? <span className="help-block text-danger"> { this.props.get('password') } </span> : (null) }
												<Input type="text" name="email" class={this.state.errorEmail} onChange={ (e) => this.onChange(e.target) }/>
											</FormGroup>
											<FormGroup>
												<Label>Nueva contraseña</Label> { this.props.has('password') ? <span className="help-block text-danger"> { this.props.get('password') } </span> : (null) }
												<Input type="password" maxlength="16" name="password" class={this.state.errorPassword} onChange={ (e) => this.onChange(e.target) }/>
											</FormGroup>
											<Button color="primary" className="pull-right" onClick={
												() => this.props.passwordUpdate( {"email": this.state.email, "password": this.state.password}, this.state.token )
											}> Cambiar contraseña </Button>
										</CardBody>
										:
										(<CardBody style={{"marginTop": "-100px"}}>
											<div className="text-center mb-2"><img src={logo_login} width="120" alt="logo"/></div>
											<hr className="mb-4" />
											<h1>:( Token inválido</h1>
											<p className="text-muted">Lo sentimos su token ha expirado o es inválido.</p>
											<Link to="/password-reset">¿Intentar de nuevo?</Link>
										</CardBody>)
									}
								</Card>
							</Col>
						</Row>
					</Container>
					<Notification />
					<Preloader />
				</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		errors: state.notificationReducer.errors
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		passwordUpdate: (data, token) => dispatch(passwordUpdate(data, token)),
		clear: (field, prefix) => dispatch(clear(field, prefix)),
        has: (field) => dispatch(has(field)),
        get: (field) => dispatch(get(field))
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPasswordForm);
