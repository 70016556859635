import React from 'react';

export default class ModalBase extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            shown: false
        };
        this.killClick = this.killClick.bind(this);
        this.handleBackdropClick = this.handleBackdropClick.bind(this);
    }

    killClick(e) {
        // hace clic en el contenido no debe cerrar el modal
        e.stopPropagation();
    }

    handleBackdropClick() {
        // Al hacer clic en el fondo, el usuario solicita que el modal se cierra.
        // Señalar que el modal tiene nada que decir sobre si realmente se cierra. el propietario de la
        // Modal posee el estado. esto sólo "pide" que se cierre.
        this.props.onRequestClose();
    }

    render() {
        var classConten="dialog__content min",
            progress= '';
        if(this.props.max === "si"){
            classConten = "dialog__content max";
        }else if(this.props.max === "med"){
            classConten="dialog__content";
        } else {
            classConten="dialog__content " + this.props.max;
        }
        
        if( this.props.progressNum !== ''){
            progress = (
                <div className="dialog_progress">
                    <span className="dialog_progress_num">{this.props.progressNum}%</span>
                    <span className="dialog_progress_tex">{this.props.progressText}</span>
                </div>
            );
        }

        let icon = "icon-close icons";
        if(this.props.max === "complete") {
            icon = "icon-check icons";
        }
        return (
            <div className="dialog dialog--open">
                <div className="dialog__overlay" onClick={this.handleBackdropClick} />
                {progress}
                <div className={ classConten } onClick={this.killClick}>
                  {
                    ( (this.props.max !== "pay") && (this.props.max !== "complete") ) ? (                  
                        <div className="dialog-header" onClick={this.handleBackdropClick}>
                            <span className="dialog-title"> {this.props.title} </span>
                            <span className="dialog-close"> <i className="icon-close icons"></i> </span>
                        </div>
                    ) : ('')
                  }
                  <div className="dialog-body">
                    {this.props.children}
                  </div>
                  {
                    ( (this.props.max === "pay") || (this.props.max === "complete") ) ? (
                        <div className="dialog-header" onClick={this.handleBackdropClick}>
                            <span className="dialog-title"> {this.props.title} </span>
                            <span className="dialog-close"> <i className={ icon } ></i> </span>
                        </div>
                    ) : ('')
                  }
                </div>
              </div>
        )
    }
}
