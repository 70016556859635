import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import logo from '../../assets/img/brand/logo.png'
import sygnet from '../../assets/img/brand/sygnet.svg'
import ic_settings from '../../assets/icon/ic_settings.svg'

import { logout } from '../../store/actions/userActions';


const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {
  render() {

    // eslint-disable-next-line
    const { children, ...attributes } = this.props;
    let username;
    if(this.props.session){
      if (this.props.session.type !== 1 && this.props.session.type !== 4)
        username = `${ this.props.commerce.name } - ${this.props.session.name}`

      else
        username = `${this.props.session.name}`
    }


    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{ src: logo, width: 134, height: 56, alt: 'CoreUI Logo' }}
          minimized={{ src: sygnet, width: 30, height: 30, alt: 'CoreUI Logo' }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />

        <Nav className="ml-auto" navbar>
          <AppHeaderDropdown direction="down">
            <DropdownToggle nav>
              { username }
              <img src={ic_settings} className="img-avatar" alt="admin@bootstrapmaster.com" />
            </DropdownToggle>
            <DropdownMenu right style={{ right: 'auto' }}>
              <Link to="/perfil">
                <DropdownItem>
                    <i className="fa fa-user"></i> Perfil
                </DropdownItem>
              </Link>
			  {
				  (this.props.session.type === 2) ?
						(<Link to="/comercio">
							<DropdownItem>
								<i className="fa fa-industry"></i> Comercio
							</DropdownItem>
						</Link>)
					: null
			  }
              <DropdownItem divider />
              <DropdownItem onClick={() => this.props.logout()}>
                <i className="fa fa-lock"></i>
                Cerrar sesión
              </DropdownItem>
            </DropdownMenu>
          </AppHeaderDropdown>
        </Nav>
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

//export default DefaultHeader;




const mapStateToProps = (state) => {
  return {
    session: state.clientReducer.session,
    commerce: state.commerceReducer.commerce || localStorage.getItem("commerce"),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultHeader);

