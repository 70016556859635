import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Card, CardBody, Col, Container, Input, Row, Label, FormGroup } from 'reactstrap';
import { Preloader, Notification } from '../Plugins/';
import logo from '../../assets/img/brand/logo.png';
import { confirmUser, verifyToken } from '../../store/actions/userActions';
import { clear, has, get } from '../../store/actions/notificationsActions';

class Confirmation extends Component {
	constructor(props){
		super(props);

		this.state = {
			user: {
				email: '',
				password: '',
				password_confirmation: ''
			},
			errorPassword: '',
			errorPasswordR: '',
			activeBtnLogin: false,
			valid_token: undefined,
			token: this.props.match.params.token
		};

		this.props.verifyToken(this.state.token);
	}

	onChange = (target) => {
		const { name, value } = target;

		if(name === 'password'){
			let classError = '';
			if( value.length < 6 ){
				classError = 'input-error';
			} else {
				classError = '';
			}

			this.setState(prevState => ({
				...prevState,
				errorPasswordR: classError
			}));
		}

		if(name === 'password_confirmation'){
			let classError = '';
			let activeBtnLogin = false;
			if( value.length < 6 ){
				classError = 'input-error';
			} else if (this.state.user.password !== value){
				classError = 'input-error';
			} else {
				classError = '';
				activeBtnLogin = true;
			}

			this.setState(prevState => ({
				...prevState,
				errorPassword: classError,
				activeBtnLogin: activeBtnLogin
			}));
		}


		this.setState(prevState => ({
			...prevState,
			user: {
				...prevState.user,
				[name]: value
			}
		}));
	};

	componentDidMount() {
		this.setState((prevState) => {
			return ({
				...prevState,
				user: {
					...prevState.user
				},
				valid_token: prevState.valid_token
			});
		});
	}

	render(){
		document.body.setAttribute("class", "democlass");

		return (
			<div className="app flex-row align-items-center">
					<Container>
						<Row className="justify-content-center">
							<Col md="6">
								<Card className="p-4">
									{
										(this.props.valid_token !== undefined) ?
											this.props.valid_token ?
												(<CardBody>
													<div className="text-center mb-2"><img src={logo} width="200" alt="logo"/></div>
													<hr className="mb-4" />
													<h2>Establece una contraseña para tu cuenta</h2>
													<p className="text-muted">Ahora define una contraseña para tu cuenta</p>

													<FormGroup>
														<Label>Crea tu contraseña</Label> { this.props.has('password') ? <span className="help-block text-danger"> { this.props.get('password') } </span> : (null) }
													<Input type="password" maxlength="16" name="password" className={this.state.errorPasswordR} onChange={ (e) => this.onChange(e.target) }/>
													</FormGroup>
													<FormGroup className="mb-3">
														<Label>Confirma tu contraseña</Label> { this.props.has('password_confirmation') ? <span className="help-block text-danger"> { this.props.get('password_confirmation') } </span> : (null) }
													<Input type="password" maxlength="16" name="password_confirmation"  className={this.state.errorPassword}  onChange={ (e) => this.onChange(e.target) }/>
													</FormGroup>	
													{
														this.state.activeBtnLogin ? (
															<Button color="primary" className="pull-right" onClick={ () => this.props.confirmUser(this.state.user, this.state.token) }> Crear contraseña </Button>
														) : (
															<button type="button" className="pull-right btn btn-light">Crear contraseña</button>
														)
													}
												</CardBody>)
												:
												(<CardBody>
													<div className="text-center mb-2">
														<Link to="/">
														<img src={logo} width="200" alt="logo"/>
														</Link>
													</div>
													<hr className="mb-4" />
													<h1>:( Enlace no válido</h1>
													<p className="text-muted">Lo sentimos pero tu enlace expiró o no es correcto.</p>
												</CardBody>)
											:
											(null)
									}
								</Card>
							</Col>
						</Row>
					</Container>
					<Preloader />
					{/*<Notification />*/}
				</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		notification: state.notificationReducer,
		valid_token: state.clientReducer.valid_token
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		confirmUser: (user, token) => dispatch(confirmUser(user, token)),
		verifyToken: (token) => dispatch(verifyToken(token)),
		clear: (field, prefix) => dispatch(clear(field, prefix)),
        has: (field) => dispatch(has(field)),
        get: (field) => dispatch(get(field))
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(Confirmation);