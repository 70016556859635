const initialState = {
    bank: {},
    banks: [],
    selecteBanks: [],
    commerces: [],
    alertBanc: false
};

const bankReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'FETCH_COMMERCES':
            return {...state, commerces: action.banks };

        case 'FETCH_BANKS':
            return {...state, banks: action.banks };

        case 'FETCH_BANK':
            return {...state, bank: action.bank };

        case 'STORE_BANK':
            return { ...state, bank: action.bank };

        case 'SELECTE_BANK':
            return { ...state, selecteBanks: action.selecteBanks };

        case 'ALERT_BANK':
            return { ...state, alertBanc: action.alertBanc };

        default:
            return state;

    }
};

export default bankReducer;