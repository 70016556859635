import React, { Component } from 'react';
import PropTypes from 'prop-types';
import icons_1 from '../../assets/icon/icons_1.svg';
import icons_2 from '../../assets/icon/icons_2.svg';
import icons_3 from '../../assets/icon/icons_3.svg';
import icons_4 from '../../assets/icon/icons_4.svg';
import icons_5 from '../../assets/icon/icons_5.svg';
import { Link } from 'react-router-dom';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultFooter extends Component {
  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;
    let class_1 = 'btn_footer';
    let class_2 = 'btn_footer';
    let class_3 = 'btn_footer';
    let class_4 = 'btn_footer';
    let class_5 = 'btn_footer';
    if( ( window.location.pathname === '/' ) || (window.location.pathname  === '/tasa') ){
      class_1 = 'btn_footer active'
    }
    if( window.location.pathname === '/registrar-pago' ){
      class_3 = 'btn_footer active'
    }
    return (
      <React.Fragment>
        <div className={class_1}>
          <Link to="/tasa">
            <img src={ icons_4 } alt ="Tasas"/>
          </Link>
        </div>
        <div className={class_2}>
          <Link to="/balance?buscar=true">
          <img src={ icons_3 } alt ="Busqueda"/>
          </Link>
        </div>
        <div className={class_3}>
          <Link to="/registrar-pago">
            <img src={ icons_1 } alt ="Reguistro Nuevo"/>
          </Link>
        </div>
        <div className={class_4}>
          <Link to="/balance">
            <img src={ icons_2 } alt ="Balance de transaccion"/>
          </Link>
        </div>
        <div className={class_5}>
          <Link to="/perfil">
            <img src={ icons_5 }  alt ="Perfil"/>
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

export default DefaultFooter;
