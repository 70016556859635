const initialState = {
    transaction: sessionStorage.getItem('pay') ? JSON.parse(sessionStorage.getItem('pay')) : {},
    wallets : [],
    dash: 0,
    rates : [
        {
            min : 0,
            max : 0,
            price : 0,
            band_id : 1,
            bank_id : 1
        }
    ],
    crypto : 1,
    transactions: {
        data: [],
        current_page: 0,
        total: 0,
        per_page: 30
    },
    transactions_day: {
        data: [],
        current_page: 0,
        total: 0,
        per_page: 30
    },
    day: '',
    fromF : false,
    dataFilter: null
};

const transactionReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'STORE_TRANSACTION':
            return { ...state, transaction: action.transaction };

        case 'FETCH_TRANSACTIONS':
            return { ...state, transactions: action.transactions };

        case 'FETCH_DAY':
            return { ...state, day: action.day };

        case 'FETCH_FORMF':
            return { ...state, fromF: !state.fromF };
        
        case 'FETCH_TRANSACTIONS_DAY':
            return { ...state, transactions_day: action.transactions_day };

        case 'FETCH_CRYPTO':
            return { ...state, crypto: action.crypto };

        case 'FETCH_DATA_FILTER':
            return { ...state, dataFilter: action.dataFilter };

        case 'FETCH_MY_WALLETS':
            return { ...state, wallets: action.wallets };

        case 'FETCH_RATES':
            return { ...state, rates: action.rates };

        case 'FETCH_DASH':
            return { ...state, dash: action.dash };

        default:
            return state;
    }
};

export default transactionReducer;
