import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Line } from 'react-chartjs-2';
import {
  Card,
  CardBody,
  UncontrolledTooltip,
  CardFooter,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';

import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { currencyFormatter } from '../../handlers/Utils';

import {getDashboard} from '../../store/actions/commerceActions';
import { avg_dashboard, avg_dashboard_week } from '../../store/actions/tasaActions';



class Dashboard extends Component {
  constructor(props) {
    super(props);
    
    this.props.getDashboard();
    this.props.avg_dashboard();

    this.toggle = this.toggle.bind(this);
    this.onRadioBtnClick = this.onRadioBtnClick.bind(this);

    this.state = {
      dropdownOpen: false,
      radioSelected: 2,
      type_graf_active: 0
    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  onRadioBtnClick(radioSelected) {
    this.setState({
      radioSelected: radioSelected,
    });
  }

  graficData = (type) => {
    if (type === 2){
      this.setState({
        type_graf_active: 2,
      });
      // this.props.grafTasaMonth();
    }else if(type === 1){
      this.setState({
        type_graf_active: 1,
      });
      this.props.avg_dashboard_week();
    }else {
      this.setState({
        type_graf_active: 0,
      });
      this.props.avg_dashboard();
    }
  }

  render() {

    var max = this.props.max;
    var min = this.props.min;

    let mainChartOpts = {
      tooltips: {
        enabled: false,
        custom: CustomTooltips,
        intersect: true,
        mode: 'index',
        position: 'nearest',
        callbacks: {
          labelColor: function(tooltipItem, chart) {
            return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor }
          }
        }
      },
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              drawOnChartArea: false,
            },
            ticks: {
              fontColor:' #9e9d9d',
            }
          }],
        yAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: false,
              min: min - (min * 0.02),
              stepSize: ((max * 1.2 ) - (min * 0.8)) * 0.2,
              max: max * 1.2,
              fontColor:' #9e9d9d',
              callback: function(label, index, labels) {
                  return currencyFormatter( label, 2);
              }
            },
          }],
      },
      elements: {
        point: {
          radius: 0,
          hitRadius: 10,
          hoverRadius: 4,
          hoverBorderWidth: 3,
        },
      },
    };

    let comercios_activos = '';
    
    if( this.props.dashboard.hasOwnProperty('comercios_activos') ){
      if( this.props.dashboard.comercios_activos.length > 0){
        comercios_activos = this.props.dashboard.comercios_activos.map( (comercio, index) => {
          return (
            <div key={ index } className="callout callout-info progress-group">
              <div className="progress-group-header">
                <i className="icon-badge progress-group-icon"></i>
                <span className="title">{ comercio.name }</span>
                <span className="ml-auto font-weight-bold">Monto BTC: { comercio.total_BTC }</span>
              </div>
              <div className="progress-group-header">
              <span className="title">RIF: { comercio.rif }</span>
                <span className="ml-auto font-weight-bold">Monto Bs.S.: { comercio.VES_by_BTC }</span>
              </div>
            </div>
          );
        });
      }
    }

    let comercios_volumenTop_semana = '';
    
    if( this.props.dashboard.hasOwnProperty('comercios_volumenTop_semana') ){
      if( this.props.dashboard.comercios_volumenTop_semana.length > 0){
        comercios_volumenTop_semana = this.props.dashboard.comercios_volumenTop_semana.map( (user, index) => {
          return (
            <div key={ index } className="progress-group">
              <div className="progress-group-header">
                <i className="icon-badge progress-group-icon"></i>
                <span className="title">{ user.name }</span>
                <span className="ml-auto font-weight-bold">Monto BTC: { user.total_BTC }</span>
              </div>
              <div className="progress-group-header">
              <span className="title">RIF: { user.rif }</span>
                <span className="ml-auto font-weight-bold">Monto Bs.S.: { user.VES_by_BTC }</span>
              </div>
            </div>
          );
        });
      }
    }

    let comercios_volumenTop_mes = '';
    
    if( this.props.dashboard.hasOwnProperty('comercios_volumenTop_mes') ){
      if( this.props.dashboard.comercios_volumenTop_mes.length > 0){
        comercios_volumenTop_mes = this.props.dashboard.comercios_volumenTop_mes.map( (user, index) => {
          return (
            <div key={ index } className="progress-group">
              <div className="progress-group-header">
                <i className="icon-badge progress-group-icon"></i>
                <span className="title">{ user.name }</span>
                <span className="ml-auto font-weight-bold">Monto BTC: { user.total_BTC }</span>
              </div>
              <div className="progress-group-header">
              <span className="title">RIF: { user.rif }</span>
                <span className="ml-auto font-weight-bold">Monto Bs.S.: { user.VES_by_BTC }</span>
              </div>
            </div>
          );
        });
      }
    }

    let comercios_volumenTop_dia = '';
    
    if( this.props.dashboard.hasOwnProperty('comercios_volumenTop_dia') ){
      if( this.props.dashboard.comercios_volumenTop_dia.length > 0){
        comercios_volumenTop_dia = this.props.dashboard.comercios_volumenTop_dia.map( (user, index) => {
          return (
            <div key={ index } className="progress-group">
              <div className="progress-group-header">
                <i className="icon-badge progress-group-icon"></i>
                <span className="title">{ user.name }</span>
                <span className="ml-auto font-weight-bold">Monto BTC: { user.total_BTC }</span>
              </div>
              <div className="progress-group-header">
              <span className="title">RIF: { user.rif }</span>
                <span className="ml-auto font-weight-bold">Monto Bs.S.: { user.VES_by_BTC }</span>
              </div>
            </div>
          );
        });
      }
    }

    let top_ciudades = '';
    
    if( this.props.dashboard.hasOwnProperty('top_ciudades') ){
      if( this.props.dashboard.top_ciudades.length > 0){
        top_ciudades = this.props.dashboard.top_ciudades.map( (data, index) => {
          return (
            <Col key={ index } sm="3" className="pull-left">
              <div className="callout callout-info">
                <small className="text-muted">{data.city}</small>
              </div>
            </Col>
          );
        });
      }
    }

    return (
      <div className="animated fadeIn">
        <Row>
          <Col xs="12" sm="6" lg="2" className="box-dashboard">
            <Card className="text-white bg-info">
              <i id="info-tasa" className="icon-info position-absolute info-dashboard-tooltip"></i>
              <UncontrolledTooltip placement="bottom" target="info-tasa">
                Precio / BTC
              </UncontrolledTooltip>
              <CardBody className="p-5 p-l-10 card-body">
                <div className="text-value">{ currencyFormatter( this.props.dashboard.tasa_del_dia, 2) }</div>
                <div>Tasa del día</div>
              </CardBody>
            </Card>
          </Col>

          <Col xs="12" sm="6" lg="2" className="box-dashboard">
            <Card className="text-white bg-info">
              <i id="info-valueup" className="icon-info position-absolute info-dashboard-tooltip"></i>
              <UncontrolledTooltip placement="bottom" target="info-valueup">
                Valor más alto en el día
              </UncontrolledTooltip>
              <CardBody className="p-5 p-l-10 card-body">
                <div className="text-value">{ currencyFormatter( this.props.dashboard.alto, 2) }</div>
                <div>Alto del día</div>
              </CardBody>
            </Card>
          </Col>
          
          <Col xs="12" sm="6" lg="2" className="box-dashboard">
            <Card className="text-white bg-info">
              <i id="info-valuedown" className="icon-info position-absolute info-dashboard-tooltip"></i>
              <UncontrolledTooltip placement="bottom" target="info-valuedown">
                Valor más bajo del día
              </UncontrolledTooltip>
              <CardBody className="p-5 p-l-10 card-body">
                <div className="text-value">{ currencyFormatter( this.props.dashboard.bajo, 2) }</div>
                <div>Bajo del día</div>
              </CardBody>
            </Card>
          </Col>

          <Col xs="12" sm="6" lg="2" className="box-dashboard">
            <Card className="text-white bg-info">
              <i id="info-cambio" className="icon-info position-absolute info-dashboard-tooltip"></i>
              <UncontrolledTooltip placement="bottom" target="info-cambio">
                Tasa de cambio del día
              </UncontrolledTooltip>
              <CardBody className="p-5 p-l-10 card-body">
                <div className="text-value">{ currencyFormatter( this.props.dashboard.cambio, 8) }</div>
                <div>Cambio del día</div>
              </CardBody>
            </Card>
          </Col>

          <Col xs="12" sm="6" lg="4" className="box-dashboard">
            <Card className="text-white bg-info">
              <i id="info-vol" className="icon-info position-absolute info-dashboard-tooltip"></i>
              <UncontrolledTooltip placement="bottom" target="info-vol">
                Cantidad de Bitcoins transados en el día
              </UncontrolledTooltip>
              <CardBody className="p-5 p-l-10 card-body">
                <div className="text-value">BTC { currencyFormatter( this.props.dashboard.volumen, 2) }</div>
                <div>Volumen del día</div>
              </CardBody>
            </Card>
          </Col>

        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
              <CardFooter>
                <Row className="text-center">
                  <Col sm={12} md className="mb-sm-2 mb-0">
                    <div className="text-muted">Número de operaciones</div>
                    <strong>{ this.props.dashboard.numero_operaciones }</strong>
                    
                  </Col>
                  <Col sm={12} md className="mb-sm-2 mb-0">
                    <div className="text-muted">Volumen Bs.S.</div>
                    <strong>Bs.S. { currencyFormatter( this.props.dashboard.volumen_bs, 2) }</strong>
                    
                  </Col>
                  <Col sm={12} md className="mb-sm-2 mb-0 d-md-down-none">
                    <div className="text-muted">Volumen BTC</div>
                    <strong>BTC { currencyFormatter( this.props.dashboard.volumen_btc, 2) }</strong>
                    
                  </Col>
                  <Col sm={12} md className="mb-sm-2 mb-0 d-md-down-none">
                    <div className="text-muted">Nuevos usuarios</div>
                    <strong>{ this.props.dashboard.nuevos_usuarios }</strong>
                    
                  </Col>
                </Row>
              </CardFooter>
                <Row>
                  <Col sm="10">
                    <CardTitle className="mb-0">Volúmenes transados en BTC</CardTitle>
                  </Col>
                </Row>
                <ul className="list_cripto_money">
                    <li className="active" onClick={ () => this.graficData(0) } >
                      {
                        (this.state.type_graf_active === 0)? ( <span className="title_tasa"> DÍAS  </span> ) : ( <span> DÍAS  </span> )
                      }
                    </li>
                    <li onClick={ () => this.graficData(1) }>
                      {
                        (this.state.type_graf_active === 1)? ( <span className="title_tasa"> SEMANAS  </span> ) : ( <span> SEMANAS  </span> )
                      }
                    </li>
                </ul>
                <div className="chart-wrapper" style={{ height: 300 + 'px', marginTop: 40 + 'px' }}>
                  <Line data={this.props.mainChart} options={mainChartOpts} height={400} />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col xs="12" md="12" xl="6">
                    <hr className="mt-0" />
                    <b>Top de comercios activos</b>
                    {
                      comercios_activos
                    }
                  </Col>
                  <Col xs="12" md="12" xl="6">
                    <hr className="mt-0" />
                    <b>Top de comercios con más volumen del día</b>
                    <ul className="callout callout-success">
                      {
                        comercios_volumenTop_dia
                      }
                    </ul>
                  </Col>
                  <Col xs="12" md="12" xl="6">
                    <hr className="mt-0" />
                    <b>Top de comercios con más volumen de la semana</b>
                    <ul className="callout callout-success">
                      {
                        comercios_volumenTop_semana
                      }
                    </ul>
                  </Col>
                  <Col xs="12" md="12" xl="6">
                    <hr className="mt-0" />
                    <b>Top comercios con más volumen del mes</b>
                    <ul className="callout callout-success">
                      {
                        comercios_volumenTop_mes
                      }
                    </ul>
                  </Col>
                  <Col xs="12" md="12" xl="12">
                    <hr className="mt-0" />
                    <b>Top de ciudades</b>
                  </Col>
                  {
                    top_ciudades
                  }
                </Row>

              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
	return {
    dashboard: state.commerceReducer.dashboard,
    mainChart: state.tasaReducer.mainChart,
    max: state.tasaReducer.max,
    min: state.tasaReducer.min,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
    getDashboard: () => dispatch(getDashboard()),
    avg_dashboard: () => dispatch(avg_dashboard()),
    avg_dashboard_week: () => dispatch(avg_dashboard_week()),
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);