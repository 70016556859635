const initialState = {

    wallet: {
        name : '', 
        public_key : '', 
        commerce_id : '', 
        crypto_id : ''
    },
    wallets: {
        "current_page": 1,
        "data": [],
        "first_page_url": "",
        "from": 1,
        "last_page": 1,
        "last_page_url": "",
        "next_page_url": null,
        "path": "",
        "per_page": 10,
        "prev_page_url": null,
        "to": 1,
        "total": 1
    },
    listCrypto: [],
    listUserNoWallet:{
        "current_page": 1,
        "data": [],
        "first_page_url": "",
        "from": 1,
        "last_page": 1,
        "last_page_url": "",
        "next_page_url": null,
        "path": "",
        "per_page": 10,
        "prev_page_url": null,
        "to": 1,
        "total": 1
    },
    valid :false,
    listWalletNoUser:{
        "current_page": 1,
        "data": [],
        "first_page_url": "",
        "from": 1,
        "last_page": 1,
        "last_page_url": "",
        "next_page_url": null,
        "path": "",
        "per_page": 10,
        "prev_page_url": null,
        "to": 1,
        "total": 1
    },
    isForOwner: sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')).type === 1 : false,
    total_balance: 0
};

const walletReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'STORE_WALLET':
            return { ...state, wallet: action.wallet };

        case 'FETCH_WALLETS':
            return { ...state, wallets: action.wallets};
            
        case 'OWNER_VIEW_LIST_TYPE':
            return {...state, isForOwner: action.isForOwner };
        
        case 'FETCH_VALID':
            return { ...state, valid: action.valid };

        case 'FETCH_CRYTOS':
            return { ...state, listCrypto: action.listCrypto };

        case 'FETCH_USER_NO_WALLET':
            return { ...state, listUserNoWallet: action.listUserNoWallet };

        case 'FETCH_WALLET_BALANCE':
            return { ...state, total_balance: action.total_balance};

        case 'FETCH_WALLET_NO_USER':
            return { ...state, listWalletNoUser: action.listWalletNoUser };

        default:
            return state;
    }
};

export default walletReducer;