
import { URL } from '../../config.js';
import NotificationHandler from '../../handlers/Notifications';
import axios from 'axios';
import store from '../store';
import {fetchBanks} from './bankActions';
import { logout } from './userActions';

const companyBankAssignation = () => {
    return (dispatch) => {
        dispatch({type: "WAIT_FOR_RESPONSE"});
        let selecte_Banks = store.getState().bankReducer.selecteBanks;
        return axios.post(URL + '/bank-commerce', {"bank": selecte_Banks.toString()}).then( (res) => {

            dispatch({
                type: 'BANK_ASSIGNATION',
                commerces: res.data
            });
			dispatch({
				type: 'NOTIFICATION',
				notification: NotificationHandler(res)
            });

            dispatch(fetchBanks( ));
            
            dispatch({type: "WAIT_FOR_RESPONSE"});
        }).catch( (err) => {
            if(err.response.status === 401){
                dispatch( logout() );
            }
            dispatch({
                type: 'NOTIFICATION',
                notification: NotificationHandler(err.response),
            });
        });
    }
};

/**
 * @description fetch selected user's apps
 * @param id
 * @returns {function(*): Promise<AxiosResponse<any> | never | never>}
 */
const fetchCommerce = (id) => {
    return (dispatch) => {
        let url = URL + '/commerce/show?id=' + id;

        return axios.get(url).then( (res) => {

            sessionStorage.setItem('commerce', JSON.stringify(res.data));

            dispatch({
                type: 'FETCH_COMMERCE',
                commerce: res.data
            })
        }).catch((err) => {
            if( (err.response.status === 401) && (err.response.data.error.message === 'Token has expired')){
                dispatch( logout() );
            }
            dispatch({
                type: 'NOTIFICATION',
                notification: NotificationHandler(err.response)
            })
        });
    }
};

const fetchCommerceVerify = (id ) => {
    return (dispatch) => {
        let url = URL + '/commerce/show-verify/' + id;

        return axios.get(url).then( (res) => {

            dispatch({
                type: 'FETCH_COMMERCE',
                commerce: res.data[0]
            })
        }).catch((err) => {
            if( (err.response.status === 401) && (err.response.data.error.message === 'Token has expired')){
                dispatch( logout() );
            }
            dispatch({
                type: 'NOTIFICATION',
                notification: NotificationHandler(err.response)
            })
        });
    }
};
 
const fetchCommerceStatus = (status, page ) => {
    return (dispatch) => {

        return axios.get(URL + '/commerce/index/'+ status +'?page='+ page ).then( (res) => {

            dispatch({
                type: 'FETCH_LIQUIDATIONS',
                liquidations: res.data.commerce
            });

        }).catch((err) => {
            if( (err.response.status === 401) && (err.response.data.error.message === 'Token has expired')){
                dispatch( logout() );
            }
            let data = {
                "current_page": 1,
                "data": [],
                "first_page_url": "",
                "from": 1,
                "last_page": 1,
                "last_page_url": "",
                "next_page_url": null,
                "path": "",
                "per_page": 10,
                "prev_page_url": null,
                "to": 1,
                "total": 1
            };

            dispatch({
                type: 'FETCH_LIQUIDATIONS',
                liquidations: data
            });
            
        });
    }
};

const changeCommerceSatus = (id, status) => {
    return (dispatch) => {
        
        return axios.patch(`${URL}/commerce/active/${id}/${status ? '1' : '0'}`).then( (res) => {

            dispatch( fetchCommerces() );

        }).catch((err) => {
            if( (err.response.status === 401) && (err.response.data.error.message === 'Token has expired')){
                dispatch( logout() );
            }
            dispatch({
                type: 'NOTIFICATION',
                notification: NotificationHandler(err.response)
            })

        });
    }
};

/**
 * @description fetch selected user's apps
 * @param id
 * @returns {function(*): Promise<AxiosResponse<any> | never | never>}
 */
const fetchCommerces = ( page = 0) => {
	return (dispatch) => {
        dispatch({type: "WAIT_FOR_RESPONSE"});
		return axios.get( `${URL}/commerce/index?page=${page}` ).then((res) => {
			dispatch({
				type: 'FETCH_COMMERCES',
				commerces: res.data.commerce
            });
            dispatch({type: "WAIT_FOR_RESPONSE"});
		}).catch((err) => {
            if( (err.response.status === 401) && (err.response.data.error.message === 'Token has expired')){
                dispatch( logout() );
            }
            dispatch({type: "WAIT_FOR_RESPONSE"});
			// dispatch({
			// 	type: 'NOTIFICATION',
			// 	notification: NotificationHandler(err.response)
			// })
		});
	}
};

/**
 * @description updates user app data.
 * @returns {function(*): Promise<AxiosResponse<any> | never | never>}
 */
const updateCommerce = (data) => {

    return (dispatch) => {

        dispatch({type: "WAIT_FOR_RESPONSE"});
        return axios.put(URL + '/commerce/update', data)
			.then( (res) => {

				dispatch({
					type: 'UPDATE_COMMERCE',
					commerce: res.data
				});

				dispatch({
					type: 'NOTIFICATION',
					notification: NotificationHandler(res)
				})

			}).catch((err) => {
                if( (err.response.status === 401) && (err.response.data.error.message === 'Token has expired')){
                    dispatch( logout() );
                }
				dispatch({
					type: 'NOTIFICATION',
					notification: NotificationHandler(err.response)
				})
			});
    }
};

const changeStatus = (id, status, list = false) => {

    return (dispatch) => {

        dispatch({type: "WAIT_FOR_RESPONSE"});
        return axios.patch(URL + '/commerce/verify/'+id+'/'+status)
			.then( (res) => {

				dispatch({
					type: 'NOTIFICATION',
					notification: NotificationHandler(res)
                })
                if(list){
                    dispatch(fetchCommerceStatus(1, 1));
                }
                
			}).catch((err) => {
                if( (err.response.status === 401) && (err.response.data.error.message === 'Token has expired')){
                    dispatch( logout() );
                }
				dispatch({
					type: 'NOTIFICATION',
					notification: NotificationHandler(err.response)
				})
			});
    }
};


/**
 * @description updates user app data.
 * @returns {function(*): Promise<AxiosResponse<any> | never | never>}
 */
const getDashboard = () => {

    return (dispatch) => {

        return axios.get(URL + '/dashboard').then( (res) => {

            dispatch({
                type: 'FETCH_DASHBOARD',
                dashboard: res.data
            })
        }).catch((err) => {
            if( (err.response.status === 401) && (err.response.data.error.message === 'Token has expired')){
                dispatch( logout() );
            }
            dispatch({
                type: 'NOTIFICATION',
                notification: NotificationHandler(err.response)
            })
        });
    }
};

const testGraf = () => {

    return (dispatch) => {

        return axios.get(URL + '/testGraf/$fechaInicial/$fechaFinal').then( (res) => {

            dispatch({
                type: 'FETCH_DASHBOARD',
                dashboard: res.data
            })
        }).catch((err) => {
            if( (err.response.status === 401) && (err.response.data.error.message === 'Token has expired')){
                dispatch( logout() );
            }
            dispatch({
                type: 'NOTIFICATION',
                notification: NotificationHandler(err.response)
            })
        });
    }
};

/**
 * @param {object}
 * 
 * @description Sets each single image to be used by the prototype.
 * 
 * @returns {object}
 */
const uploadFile = ( file ) => {
    return (dispatch) => {
        dispatch({type: "WAIT_FOR_RESPONSE"});
        var data = new FormData();
        data.append('image', file);
        var config = {
            onUploadProgress: function(progressEvent) {
            //   var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
            }
        };

        axios.post(URL + '/commerce/verify-file', data, config).then(function (res) {
            dispatch({
                type: 'NOTIFICATION',
                notification: NotificationHandler(res)
            });

            dispatch(fetchCommerce(store.getState().clientReducer.session.commerce_id));
        }).catch( (err) => {
            if( (err.response.status === 401) && (err.response.data.error.message === 'Token has expired')){
                dispatch( logout() );
            }
            dispatch({
                type: 'NOTIFICATION',
                notification: NotificationHandler(err.response)
            });
        });
    }
}
export {
    companyBankAssignation,
    fetchCommerces,
    fetchCommerce,
    updateCommerce,
    getDashboard,
    testGraf,
    changeCommerceSatus,
    uploadFile,
    fetchCommerceStatus,
    fetchCommerceVerify,
    changeStatus
};
