const initialState = {
    session: (sessionStorage.getItem('user') && sessionStorage.getItem('user') !== null ) ? JSON.parse(sessionStorage.getItem('user')) : {'is_active' : false},
    user: {},
    users: {
        "current_page": 1,
        "data": [],
        "first_page_url": "",
        "from": 1,
        "last_page": 1,
        "last_page_url": "",
        "next_page_url": null,
        "path": "",
        "per_page": 10,
        "prev_page_url": null,
        "to": 1,
        "total": 1
    },
    table: {
        ready: false,
        dataTransactions: [],
        limitTransactions: 10,
        pageTransactions: 1,
        ordTransactions: 'desc',
        sortTransactions: 'id',
        searchTransactions: ''
    },
    updateStatus:{
        app_id: null,
        status_id:null
    },
    social_data: {
        name:'',
        lastname:'',
        email:'',
    }
};

const clientReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_USERS':
            return {...state, users: action.users };

        case 'FETCH_USER':
            return { ...state, user: action.user, session: action.session ? action.session : state.session };

        case 'SET_USER_DEFAULT_LANGUAGE':
            return { ...state, lang_code: action.lang_code, json_lang: action.json_lang };

        case 'VERIFY_TOKEN':
            return { ...state, valid_token: action.valid_token };

        case 'VERIFICATION_TOKEN_ERROR':
            return { ...state, valid_token: action.valid_token };

        case 'LOGIN':
            return { ...state, session: action.user };

        case 'SOCIAL_REGISTRATION_SUCCESS':
            return { ...state, valid_registration: action.valid_registration, social_data: action.social_data };

        case 'LOGOUT':
            return { ...state, session: action.user };


        default:
            return state;

    }
};

export default clientReducer;
