
const initialState = {
	bankAccount: {},
	bankAccounts: []
};

const bankReducer = (state = initialState, action) => {
	switch (action.type) {

		case 'FETCH_BANK_ACCOUNTS':
			return {
				...state,
				bankAccounts: action.bankAccounts
			};

		case 'FETCH_BANK_ACCOUNT':
			return {
				...state,
				bankAccount: action.bankAccount
			};
 
		default:
			return state;

	}
};

export default bankReducer;