let url = '';
let url_base = '';
let console_log = true;
let fb_id = '';
let google_id = '';


switch (window.location.hostname) {
	case 'localhost':
		url = 'https://api.kryptopago.com/api';
		url_base = 'https://api.kryptopago.com';
		/* url = 'http://bitcoin.test/api';
		url_base = 'http://bitcoin.test'; */
		fb_id = "386637848752955"
		google_id = "1054565547315-fo791k0gdafjj11598asldafomf1fv0t.apps.googleusercontent.com"
		break;

	case 'app.kryptopago.com':
		console_log = false;
		url = 'https://api.kryptopago.com/api';
		url_base = 'https://api.kryptopago.com';
		fb_id = "582601265600481"; // TODO: should be process.env.FACEBOOK_CLIENT_ID;  ****** por cambiar
		google_id = "356014798070-f3t8ogsd1ahtid7vgib701f0j3rt7g1j.apps.googleusercontent.com"; // TODO: should be process.env.GOOGLE_CLIENT_ID;
		break;

	default:
		url = 'http://localhost/api';
		url_base = 'http://localhost';
		fb_id = "356014798070-f3t8ogsd1ahtid7vgib701f0j3rt7g1j.apps.googleusercontent.com"
		google_id = "1054565547315-fo791k0gdafjj11598asldafomf1fv0t.apps.googleusercontent.com"
		break;
}



export const URL = url;
export const URL_BASE = url_base;
export const CONSOLE_LOG = console_log;
export const FB_APP_ID = fb_id;
export const GOOGLE_APP_ID = google_id;
