import { URL } from '../../config.js';
import NotificationHandler from '../../handlers/Notifications';
import axios from 'axios';
import store from '../store';
//import { oneOf } from '../../handlers/Utils';
import { fetchCommerce} from './commerceActions';

/**
 * @description loads the selected language json file for the given code [es, en]
 * @param code
 * @returns {Promise<any>}
 */
let loadJsonLang = (code) => {
    return new Promise( (resolve, reject) => {
        reject(new Error("Archivo de idiomas no encontrado."));
    })
};

/**
 *
 * @param target
 * @returns {Function}
 */
const setValues = (target) => {
    return (dispatch) => {
        dispatch({
            type: 'SET_VALUES',
            target: target
        })
    }
};

/**
 * @description change the current reading language for the new
 * given by code parameter
 * @param code
 * @returns {function(*): Promise<any | never>}
 */
const changeUserDefaultLanguage = (code) => {
    return (dispatch) => {

        dispatch({type: "WAIT_FOR_RESPONSE"});
        return loadJsonLang(code).then(
            response => {
                sessionStorage.setItem('lang_code', code);
                axios.defaults.headers.common['lang'] = code;
                dispatch({
                    type: "SET_USER_DEFAULT_LANGUAGE",
                    lang_code: code,
                    json_lang: response
                });
                dispatch({type: "WAIT_FOR_RESPONSE"})
            }
        )
    }
};

/**
 * @description logs in the user
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any> | never | never>}
 */
const login = (data) => {
    return (dispatch) => {
        dispatch({type: "WAIT_FOR_RESPONSE"});
        return axios.post(URL + '/auth/login', data).then( async (res) => {
            sessionStorage.clear();
            //console.log(res.data);
            sessionStorage.setItem('user', JSON.stringify(res.data.user));
            sessionStorage.setItem('token', res.data.token);
            //sessionStorage.setItem('lang_code', res.data.user);
            if( res.data.user.commerce_id !== null){
                await dispatch(fetchCommerce(res.data.user.commerce_id ));
            }
            
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token;

            var hrefData = '/tasa';
            if(res.data.user.type === 1 ){
                hrefData = '/dashboard';
            }

            window.location.href = hrefData;

            dispatch({
                type: 'LOGIN',
                user: res.data.user
            });
            dispatch({type: "WAIT_FOR_RESPONSE"});
        }).catch( (err) => {
            dispatch({
                type: 'NOTIFICATION',
                notification: NotificationHandler(err.response),
            });
        });
    }
};

/**
 * @description logs out the user
 * @returns {function(*): Promise<AxiosResponse<any> | never | never>}
 */

const clearStateUser = () => {
    return (dispatch) => {
        console.log('================================================================================================== hola clearStateUser ===============================================================');
        dispatch({
            type: 'LOGOUT',
            user: {}
        });
    }
}

const logout = () => {

    return (dispatch) => {
        dispatch({type: "WAIT_FOR_RESPONSE"});
        sessionStorage.clear();
        dispatch({
            type: 'LOGOUT',
            user: {}
        });
        return axios.post(URL + '/auth/logout').then( (res) => {
            window.location.reload();
            dispatch({type: "WAIT_FOR_RESPONSE"});
        }).catch( (err) => {
            dispatch({type: "WAIT_FOR_RESPONSE"});
            window.location.reload();
        });
    }
};

/**
 * @description register the incoming new user and sends
 * the client app url to the api to be set in the email confirm button
 * @param data
 * @param client_url
 * @returns {Function}
 */
const register = (data, client_url) => {

    return (dispatch) => {
        dispatch({type: "WAIT_FOR_RESPONSE"});

        axios.post(URL + '/register', data).then( (res) => {
            dispatch({
                type: 'REGISTER_SUCCESS',
                notification: NotificationHandler(res)
            });
        }).catch( (err) => {
            dispatch({
                type: 'REGISTER_ERROR',
                notification: NotificationHandler(err.response),
            });
        });
    }
};

const deleteUser = (id) => {

	return (dispatch) => {
		dispatch({
			type: "WAIT_FOR_RESPONSE"
		});

		axios.delete(URL + '/user/destroy/' + id).then((res) => {
			dispatch({
				type: "WAIT_FOR_RESPONSE"
			});
			dispatch(fetchUsers());
			dispatch({
				type: 'NOTIFICATION',
				notification: NotificationHandler(res)
			});
		}).catch((err) => {
            if( (err.response.status === 401) && (err.response.data.error.message === 'Token has expired')){
                dispatch( logout() );
            }
			dispatch({
				type: 'REGISTER_ERROR',
				notification: NotificationHandler(err.response),
			});
		});
	}
};

/**
 * @description register the incoming new user and sends
 * the client app url to the api to be set in the email confirm button
 * @param data
 * @param client_url
 * @returns {Function}
 */
const resendEmailConfirm = (data) => {

    return (dispatch) => {
        dispatch({type: "WAIT_FOR_RESPONSE"});

        axios.post(URL + '/resend-email-confirm', data).then( (res) => {
            dispatch({
                type: 'REGISTER_SUCCESS',
                notification: NotificationHandler(res)
            });
        }).catch( (err) => {
            dispatch({
                type: 'REGISTER_ERROR',
                notification: NotificationHandler(err.response),
            });
        });
    }
};

/**
 * @description saves new users and sends
 * @param data
 * @param client_url
 * @returns {Function}
 */
const storeUser = (data) => {

    return (dispatch) => {
        dispatch({type: "WAIT_FOR_RESPONSE"});
        if( !data.hasOwnProperty( 'id' ) ){
            axios.post(URL + '/user/store', data).then( (res) => {
                dispatch({
                    type: 'REGISTER_SUCCESS',
                    notification: NotificationHandler(res)
                });
                dispatch(fetchUsers());

            }).catch( (err) => {
                dispatch({
                    type: 'REGISTER_ERROR',
                    notification: NotificationHandler(err.response),
                });
            });
        }
        else{
            axios.put(URL + '/user/update/'+ data.id, data).then( (res) => {
                dispatch({
                    type: 'REGISTER_SUCCESS',
                    notification: NotificationHandler(res)
                });
                dispatch(fetchUsers());
            }).catch( (err) => {
                dispatch({
                    type: 'REGISTER_ERROR',
                    notification: NotificationHandler(err.response),
                });
            });
        }
    }
};

/**
 * @description sends a request for a password reset email
 * @param data
 * @returns {Function}
 */
const passwordForgotten = (data) => {

    return (dispatch) => {

        dispatch({type: "WAIT_FOR_RESPONSE"});

        axios.post(URL + '/password-reset', data).then( (res) => {

            dispatch({
                type: 'VERIFY_TOKEN',
                valid_token: res.status === 200,
                notification: NotificationHandler(res)
            });

            dispatch({type: "WAIT_FOR_RESPONSE"});

        }).catch( (err) => {

            dispatch({
                type: 'VERIFICATION_TOKEN_ERROR',
                notification: NotificationHandler(err.response)
            })
        });
    }
};

/**
 *
 * @param data
 * @returns {Function}
 */
const verifyDomain = (data) => {
    return (dispatch) => {
        axios.get(URL + '/verify-domain/'+ data).then( (res) => {

            dispatch({
                type: 'NOTIFICATION',
                notification: NotificationHandler(res)
            });

        }).catch( (err) => {

            dispatch({
                type: 'NOTIFICATION',
                notification: NotificationHandler(err.response)
            });

        });
    }
};

/**
 * @description updates the current password with the new one
 * @param data
 * @param token
 * @returns {Function}
 */
const passwordUpdate = (data, token) => {
    return (dispatch) => {

        dispatch({type: "WAIT_FOR_RESPONSE"});

        axios.post(URL + '/password-reset/' + token, data).then( (res) => {
            dispatch({
                type: 'PASSWORD_RESET',
                valid_token: res.status === 200
            });

            window.location.href = '/login';
        }).catch( (err) => {
            if( (err.response.status === 401) && (err.response.data.error.message === 'Token has expired')){
                dispatch( logout() );
            }
            dispatch({
                type: 'VERIFICATION_TOKEN_ERROR',
                notification: NotificationHandler(err.response)
            });
        });
    }
};

/**
 * @description receive a response from the api to allow the user
 * to log in
 * @param data
 * @param token
 * @returns {Function}
 */
const confirmUser = (data, token) => {
    let client_url = window.location.origin;

    return (dispatch) => {

        if (data.password === data.password_confirmation ) {

            dispatch({type: "WAIT_FOR_RESPONSE"});

            return axios.post(URL + '/confirmation/' + token, {...data, client_url}).then( (res) => {

                dispatch({
                    type: 'REGISTRATION_COMPLETED',
                    user: res.data.user
                });

                dispatch({type: "WAIT_FOR_RESPONSE"});

                window.location.href = '/login';
            }).catch( (err) => {
                if( (err.response.status === 401) && (err.response.data.error.message === 'Token has expired')){
                    dispatch( logout() );
                }
                dispatch({
                    type: 'NOTIFICATION',
                    notification: NotificationHandler(err.response)
                });
            });

        } else if (data.password_confirmation === '') {

            dispatch({
                type: 'NOTIFICATION',
                notification: {
                    "errors": {
                        "password_confirmation":[ 'Dato vacío'],
                    },
                    "level": 4,
                    "message" : 'Dato vacío'
                }
            });
        } else {
            dispatch({
                type: 'NOTIFICATION',
                notification: {
                    "errors": {
                        "password": [ ],
                        "password_confirmation": [ 'Dato vacío' ],
                    },
                    "level": 4,
                    "message" : 'Dato vacío'
                }
            });
        }

    }
};

/**
 *
 * @param data
 * @returns {Function}
 */
const verifyToken = (data) => {
    return (dispatch) => {
        dispatch({type: "WAIT_FOR_RESPONSE"});
        axios.post(URL + '/verify-token/' + data).then( (res) => {
            dispatch({
                type: 'VERIFY_TOKEN',
                valid_token: res.status === 200
            });
            dispatch({
                type: 'NOTIFICATION_TOKEN',
                valid_token: NotificationHandler(res)
            });
        }).catch( (err) => {
            if( (err.response.status === 401) && (err.response.data.error.message === 'Token has expired')){
                dispatch( logout() );
            }
            dispatch({
                type: 'VERIFICATION_TOKEN_ERROR',
                notification: NotificationHandler(err.response),
                valid_token: false
            });
            dispatch({
                type: 'NOTIFICATION_TOKEN',
                valid_token: NotificationHandler(err.response)
            });
        });
    }
};

/**
 * @description fetch all users
 * @returns {function(*): Promise<AxiosResponse<any> | never | never>}
 */
const fetchUsers = (num) => {
    return (dispatch) => {
        dispatch({type: "WAIT_FOR_RESPONSE"});
        return axios.get(URL + '/user/index?page='+num).then((res) => {
            dispatch({
                type: 'FETCH_USERS',
                users: res.data.users
            });
            dispatch({type: "WAIT_FOR_RESPONSE"});
        }).catch((err) => {
            if( (err.response.status === 401) && (err.response.data.error.message === 'Token has expired')){
                dispatch( logout() );
            }
            dispatch({
                type: 'NOTIFICATION',
                notification: NotificationHandler(err.response)
            });
            dispatch({type: "WAIT_FOR_RESPONSE"});
        });
    }
};

/**
 * @description fetch a single user
 * @param id
 * @returns {function(*): Promise<AxiosResponse<any> | never | never>}
 */
const fetchUser = (id) => {
    return (dispatch) => {

        return axios.get(URL + '/users/' + id).then( (res) => {
            dispatch({
                type: 'FETCH_USER',
                user: res.data
            })
        }).catch((err) => {
            if( (err.response.status === 401) && (err.response.data.error.message === 'Token has expired')){
                dispatch( logout() );
            }
            dispatch({
                type: 'NOTIFICATION',
                notification: NotificationHandler(err.response)
            })
        });
    }
};

/**
 * @description user profile info
 * @returns {function(*): Promise<AxiosResponse<any> | never | never>}
 */
const fetchProfileData = () => {
    return (dispatch) => {
        return axios.get(URL + '/auth/me').then( (res) => {
            let data = res.data;
            data.new_first_name = res.data.first_name;
            data.new_last_name = res.data.last_name;
            data.new_email = res.data.email;
            data.new_email_confirmation = '';
            data.old_password = '';
            data.new_password = '';
            data.new_password_confirmation = '';
            dispatch({
                type: 'FETCH_USER',
                user: {
                    ...data,
                    apps: res.data.apps.map( app => {
                        return {
                            ...app,
                            metadata: typeof app.metadata === 'string' ? { ...JSON.parse(app.metadata) } : { ...app.metadata }
                        }
                    })
                }
            })
        }).catch((err) => {
            if( (err.response.status === 401) && (err.response.data.error.message === 'Token has expired')){
                dispatch( logout() );
            }
            dispatch({
                type: 'NOTIFICATION',
                notification: NotificationHandler(err.response)
            })
        });
    }
};

/**
 * @description fetch selected user's apps
 * @param id
 * @returns {function(*): Promise<AxiosResponse<any> | never | never>}
 */
const fetchUserApps = (id) => {
    return (dispatch) => {

        return axios.get(URL + '/users/' + id + '/apps').then( (res) => {
            dispatch({
                type: 'FETCH_USER_APPS',
                apps: res.data
            })
        }).catch((err) => {
            if( (err.response.status === 401) && (err.response.data.error.message === 'Token has expired')){
                dispatch( logout() );
            }
            dispatch({
                type: 'NOTIFICATION',
                notification: NotificationHandler(err.response)
            })
        });
    }
};

/**
 * @description updates user app data.
 * @returns {function(*): Promise<AxiosResponse<any> | never | never>}
 */
const updateUser = (data) => {

    return (dispatch) => {

        dispatch({type: "WAIT_FOR_RESPONSE"});
        return axios.put(URL + '/user/update/' + data.id, data).then( (res) => {

            dispatch({
                type: 'LOGIN',
                user: res.data
            });

            sessionStorage.setItem('user', JSON.stringify(res.data));

            dispatch({
                type: 'NOTIFICATION',
                notification: NotificationHandler(res)
            })

        }).catch((err) => {
            if( (err.response.status === 401) && (err.response.data.error.message === 'Token has expired')){
                dispatch( logout() );
            }
            dispatch({
                type: 'NOTIFICATION',
                notification: NotificationHandler(err.response)
            })
        });
    }
};

/**
 * @description updates user's email
 * @returns {Function}
 */
const updateUserEmail = () => {

    return (dispatch) => {
        if (store.getState().clientReducer.user.new_email === store.getState().clientReducer.user.new_email_confirmation) {
            let aux_data = {
                "password": store.getState().clientReducer.user.old_password,
                "new_email": store.getState().clientReducer.user.new_email
            };
            dispatch({type: "WAIT_FOR_RESPONSE"});

            axios.put(URL + '/user/change-email', aux_data).then( (res) => {
                 //Reordar para despues que  me retornen la data atualizada de usuario
                // let data = res.data.user;

                // data.new_first_name = res.data.user.first_name;
                // data.new_last_name = res.data.user.last_name;
                // data.new_email = res.data.user.email;
                // data.new_email_confirmation = '';
                // data.old_password = '';
                // data.new_password = '';
                // data.new_password_confirmation = '';
                // data.apps = store.getState().clientReducer.user.apps;

                // dispatch({
                //     type: 'FETCH_USER',
                //     user: data,
                //     session: res.data.user,

                // });

                dispatch({
                    type: 'NOTIFICATION',
                    notification: NotificationHandler(res)
                })

            }).catch((err) => {
                if( (err.response.status === 401) && (err.response.data.error.message === 'Token has expired')){
                    dispatch( logout() );
                }
                dispatch({
                    type: 'NOTIFICATION',
                    notification: NotificationHandler(err.response)
                })
            });

        } else if(store.getState().clientReducer.user.new_email_confirmation === '') {
            dispatch({
                type: 'NOTIFICATION',
                notification: {
                    "errors": {
                        "new_email_confirmation":['La confirmación no debe dejarse en blanco'],
                    },
                    "level": 4,
                    "message" : 'Datos faltantes'
                }
            });
        } else if (store.getState().clientReducer.user.new_email === '') {

            dispatch({
                type: 'NOTIFICATION',
                notification: {
                    "errors": {
                        "new_email":['La confirmación no debe dejarse en blanco'],
                    },
                    "level": 4,
                    "message" : 'Datos faltantes'
                }

            });
        } else {

            dispatch({
                type: 'NOTIFICATION',
                notification: {
                    "errors": {
                        "new_email_confirmation":['La confirmación no debe dejarse en blanco'],
                        "new_email":['La confirmación no debe dejarse en blanco'],
                    },
                    "level": 4,
                    "message" : 'Datos faltantes'
                }
            });
        }

    }
};

/**
 * @description updates the user's password
 * @returns {function(*): Promise<AxiosResponse<any> | never | never>}
 */
const updatePass = ( data ) => {

    return (dispatch) => {

        dispatch({type: "WAIT_FOR_RESPONSE"});

        return axios.put(URL + '/user/change-pass', data).then( (res) => {
            dispatch({type: "WAIT_FOR_RESPONSE"});
            dispatch({
                type: 'NOTIFICATION',
                notification: NotificationHandler(res)
            })

        }).catch((err) => {
            if( (err.response.status === 401) && (err.response.data.error.message === 'Token has expired')){
                dispatch( logout() );
            }
            dispatch({type: "WAIT_FOR_RESPONSE"});
            dispatch({
                type: 'NOTIFICATION',
                notification: NotificationHandler(err.response)
            })
        });
           
    }
};

/**
 *
 * @param {Object} target
 * @param {boolean} isCheckable
 * @returns {function(*): Promise<AxiosResponse<any>>}
 *
 * Description: load all the previous user state and update each key that match with [name]
 */
const toggleUserData = (target, isCheckable = false) => {
    return (dispatch) => {
        let { name, value } = target;

        if (isCheckable) {
            value = target.checked;
        }

        if((name === 'old_password_1') || (name === 'old_password_2')) {
            let name2 = 'old_password';
            dispatch({
                type: 'FETCH_USER',
                user: {
                    ...store.getState().clientReducer.user,
                    [name]: value,
                    [name2]: value,
                }
            })
        }else{

            dispatch({
                type: 'FETCH_USER',
                user: {
                    ...store.getState().clientReducer.user,
                    [name]: value
                }
            })
        }

    }
};

/**
 *
 * @param {object} target
 * @param {number} key
 * @returns {function(*): Promise<AxiosResponse<any>>}
 *
 * Description: load all the previous user state and looks for the json key "app",
 * then maps the arran and update the position where index are equals to key.
 */
const toggleUserAppData = (target, key = 0) => {
    return (dispatch) => {
        const { name, value } = target;
        dispatch({
            type: 'FETCH_USER',
            user: {
                ...store.getState().clientReducer.user,
                apps: store.getState().clientReducer.user.apps.map( (app, index) => {
                    if(key === index) {
                        return {
                            ...app,
                            [name]: value
                        }
                    }
                    return app
                })
            }
        })
    }
};

/**
 *
 * @param {string} code
 * @param {number} key
 * @returns {function(*): Promise<AxiosResponse<any>>}
 *
 * Description: load all the previous user state and looks for the json key "app",
 * then maps the arran and update the position where index are equals to key.
 */
const setCountryCode = (code, key) => {
    return (dispatch) => {
        dispatch({
            type: 'FETCH_USER',
            user: {
                ...store.getState().clientReducer.user,
                apps: store.getState().clientReducer.user.apps.map( (app, index) => {
                    return key === index ?
                        {
                            ...app,
                            country_code: code
                        } : app
                })
            }
        })
    }
};

/**
 * @description updates the selected user's email
 * @returns {Function}
 */
const updateCustomerEmail = () => {
    return (dispatch) => {

        const new_email = store.getState().clientReducer.user.email;

        dispatch({ type: 'WAIT_FOR_RESPONSE' });
        axios.put(`${URL}/admin/users/${ store.getState().clientReducer.user.id }/email`, { new_email }).then( res => {
            dispatch({
                type: 'NOTIFICATION',
                notification: NotificationHandler(res)
            });

        }).catch( err => {
            if( (err.response.status === 401) && (err.response.data.error.message === 'Token has expired')){
                dispatch( logout() );
            }
            dispatch({
                type: 'NOTIFICATION',
                notification: NotificationHandler(err.response)
            });
        });
    }
};

/**
 * @description sends a password reset email for the current selected user
 * @returns {Function}
 */
const sendPasswordResetEmail = () => {
    return (dispatch) => {

        const client_url = window.location.origin + '/password-reset';

        dispatch({ type: 'WAIT_FOR_RESPONSE' });
        axios.put(`${URL}/admin/users/${ store.getState().clientReducer.user.id }/password`, { client_url }).then( res => {
            dispatch({
                type: 'NOTIFICATION',
                notification: NotificationHandler(res)
            });
        }).catch( err => {
            if( (err.response.status === 401) && (err.response.data.error.message === 'Token has expired')){
                dispatch( logout() );
            }
            dispatch({
                type: 'NOTIFICATION',
                notification: NotificationHandler(err.response)
            });
        })
    }
};

/**
 * updates selected user's data
 * @returns {Function}
 */
const updateCostumer = () => {
    return (dispatch) => {
        dispatch({ type: 'WAIT_FOR_RESPONSE' });
        axios.put(`${URL}/users/${ store.getState().clientReducer.user.id }`, store.getState().clientReducer.user).then( res => {
            dispatch({
                type: 'NOTIFICATION',
                notification: NotificationHandler(res)
            });
        }).catch( err => {
            if( (err.response.status === 401) && (err.response.data.error.message === 'Token has expired')){
                dispatch( logout() );
            }
            dispatch({
                type: 'NOTIFICATION',
                notification: NotificationHandler(err.response)
            });
        });
    }
};

const storeUserWallet = (data) => {
    return (dispatch) => {
        dispatch({type: "WAIT_FOR_RESPONSE"});
        axios.post(URL + '/user_wallet', data).then( (res) => {

            dispatch({type: "WAIT_FOR_RESPONSE"});
            dispatch(fetchUsers());
            dispatch({
                type: 'NOTIFICATION',
                notification: NotificationHandler(res)
            });
        }).catch( (err) => {
            if( (err.response.status === 401) && (err.response.data.error.message === 'Token has expired')){
                dispatch( logout() );
            }
            dispatch({type: "WAIT_FOR_RESPONSE"});
            dispatch({
                type: 'VERIFICATION_TOKEN_ERROR',
                notification: NotificationHandler(err.response)
            })
        });
    }
};

const disUserWallet = (data) => {
    return (dispatch) => {
        dispatch({type: "WAIT_FOR_RESPONSE"});
        axios.delete(URL + '/dis-user-wallet', {data}).then( (res) => {

            dispatch({type: "WAIT_FOR_RESPONSE"});
            dispatch(fetchUsers());
            dispatch({
                type: 'NOTIFICATION',
                notification: NotificationHandler(res)
            });
        }).catch( (err) => {
            if( (err.response.status === 401) && (err.response.data.error.message === 'Token has expired')){
                dispatch( logout() );
            }
            dispatch({type: "WAIT_FOR_RESPONSE"});
            dispatch({
                type: 'VERIFICATION_TOKEN_ERROR',
                notification: NotificationHandler(err.response)
            })
        });
    }
};

const socialLogin = user => {
    return (dispatch) => {
        dispatch({type: "WAIT_FOR_RESPONSE"});
         console.log("entro antes de bien")
        axios.post(URL + '/login-social-network', user)
            .then(async res => {
                dispatch({type: "WAIT_FOR_RESPONSE"});
                sessionStorage.clear();
                sessionStorage.setItem('user', JSON.stringify(res.data.user));
                sessionStorage.setItem('token', res.data.token);
                //sessionStorage.setItem('lang_code', res.data.user);
                if (res.data.user.commerce_id !== null) {
                	await dispatch(fetchCommerce(res.data.user.commerce_id));
                }

                axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token;

                var hrefData = '/tasa';
                if (res.data.user.type === 1) {
                	hrefData = '/dashboard';
                }

                window.location.href = hrefData;

                dispatch({
                	type: 'LOGIN',
                	user: res.data.user
                });
            })
            .catch(err => {
                dispatch({type: "WAIT_FOR_RESPONSE"});
                
                if( err.response.data.message === "not found" ){ } // no hagas nada solo quita el preloader
                else if(user.provider === "facebook"){
                    let tmp = user.name.split(" ");
                    user.name = tmp.shift();
                    user.lastname = tmp.join(' ');

                    user.facebook_id = user.userID;
                    user.google_id = null;

                    dispatch({
                        type: "SOCIAL_REGISTRATION_SUCCESS",
                        valid_registration: true,
                        social_data: user
                    })
                }
                else {
                    let tmp = user.profileObj.name.split(" ");
                    user.name = tmp.shift();
                    user.lastname = tmp.join(' ');

                    user.facebook_id = null;
                    user.google_id = user.profileObj.googleID;

                    dispatch({
                    	type: "SOCIAL_REGISTRATION_SUCCESS",
                    	valid_registration: true,
                    	social_data: user
                    })
                }
        });
    }
}

const registerFromSocial = (data) => {
    return (dispatch) => {
        dispatch({type: "WAIT_FOR_RESPONSE"});

        axios.post(URL + '/register-social-network', data)
            .then(async res => {
                dispatch({type: "WAIT_FOR_RESPONSE"});

                sessionStorage.clear();
                sessionStorage.setItem('user', JSON.stringify(res.data.user));
                sessionStorage.setItem('token', res.data.token);
                //sessionStorage.setItem('lang_code', res.data.user);
                if (res.data.user.commerce_id !== null) {
                	await dispatch(fetchCommerce(res.data.user.commerce_id));
                }

                axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token;

                var hrefData = '/tasa';
                if (res.data.user.type === 1) {
                	hrefData = '/dashboard';
                }

                window.location.href = hrefData;

                dispatch({
                	type: 'LOGIN',
                	user: res.data.user
                });
            })
            .catch(err => {
                if( (err.response.status === 401) && (err.response.data.error.message === 'Token has expired')){
                    dispatch( logout() );
                }
                dispatch({
                    type: 'VERIFICATION_TOKEN_ERROR',
                    notification: NotificationHandler(err.response)
                })
            })
    }
}

const changeUserSatus = (id, status) => {
    return (dispatch) => {
        
        return axios.patch(`${URL}/user/active/${id}/${status}`).then( (res) => {

            dispatch( fetchUsers() );

        }).catch((err) => {
            if( (err.response.status === 401) && (err.response.data.error.message === 'Token has expired')){
                dispatch( logout() );
            }
            dispatch({
                type: 'NOTIFICATION',
                notification: NotificationHandler(err.response)
            })

        });
    }
};

export {
    // auth
    login,
    verifyToken,
    confirmUser,
    passwordForgotten,

    // basics
    toggleUserData,
    toggleUserAppData,
    setCountryCode,
    register,
    changeUserDefaultLanguage,
    setValues,
    logout,

    // fetch
    fetchProfileData,
    fetchUser,
    fetchUsers,
    fetchUserApps,

    // update
    updateUser,
    updateCostumer,
    updateCustomerEmail,
    sendPasswordResetEmail,
    passwordUpdate,
    updateUserEmail,
    updatePass,

    registerFromSocial,
    storeUser,
    socialLogin,
    storeUserWallet,
    disUserWallet,
	deleteUser,
    verifyDomain,
    resendEmailConfirm,

    changeUserSatus,
    clearStateUser
};
