export default {
	items: [{
            name: 'Liquidaciones',
            url: '/liquidaciones',
            icon: 'fa fa-money',
        },
		{
			name: 'Cuentas por verificar',
			url: '/admin-cuentas-bancarias',
			icon: 'icon-check',
		}
	]
};
