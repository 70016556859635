import { combineReducers } from 'redux';
import clientReducer from './clientReducer';
import notificationReducer from './notificationReducer';
import tasaReducer from './tasaReducer';
import walletReducer from './walletReducer';
import transactionReducer from './transactionReducer';
import bankReducer from './bankReducer';
import commerceReducer from './commerceReducer';
import liquidationReducer from './liquidationReducer';
import withdraw from './withdraw';


export default combineReducers({
    clientReducer,
    notificationReducer,
    tasaReducer,
    walletReducer,
    transactionReducer,
    bankReducer,
    commerceReducer,
    liquidationReducer,
    withdraw
});