
export const oneOf = (types = [], type) => {
	for (let t of types) {
		if (type === t) {
			return true
		}
	}
	return false
}

/**
 * 	https: //developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/ceil
 *
 * Decimal adjustment of a number.
 *
 * @param   {String}    type    The type of adjustment.
 * @param   {Number}    value   The number.
 * @param   {Integer}   exp     The exponent (the 10 logarithm of the adjustment base).
 * @returns {Number}            The adjusted value.
 */
function decimalAdjust(type, value, exp) {
	// If the exp is undefined or zero...
	if (typeof exp === 'undefined' || +exp === 0) {
		return Math[type](value);
	}
	value = +value;
	exp = +exp;
	// If the value is not a number or the exp is not an integer...
	if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
		return NaN;
	}
	// Shift
	value = value.toString().split('e');
	value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
	// Shift back
	value = value.toString().split('e');
	return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
}

let Mate = {};
if (!Mate.round) {// Decimal round
	Mate.round = function (value, exp) {
		return decimalAdjust('round', value, exp);
	};
}
if (!Mate.floor) {// Decimal floor
	Mate.floor = function (value, exp) {
		return decimalAdjust('floor', value, exp);
	};
}
if (!Mate.ceil) {// Decimal ceil
	Mate.ceil = function (value, exp) {
		return decimalAdjust('ceil', value, exp);
	};
}
export {Mate};


/**
 * It takes a nested prop as a string separared by dots, iterates over it
 * asking if the prop exists and returns the value if it exists,
 * returns null otherwise
 *
 * It reads as exist 'length' in 'object'? if not use the 'default'
 *
 * @param  {String} prop				The string with prop to find
 * @param  {Object} obj					The object where it will ask if the prop exist
 * param      {Any} defaultReturnValue 	An optional default value in case you need an empty array or object
 * @return {Any}						The value contained in obj at path or null if it dosen't exist
 *
 * @example
 *
 * 		exist('this.props.property.length', this)
 *		exist('good.picture_urls.0', this) 				// good.picture_urls[0]
 *		exist('this.props.json_lang.component.forgeted_trans', this, []).map( ... )
 *		exist(`this.props.properties.${'algo'+2}.otro`)	// is 'this.props.properties.algo2.otro'
 *
 */
export const exist = (prop, obj, defaultReturnValue = null) => {
	const paths = prop.split('.');
	let value = obj;
	let idx = 0;

	while (idx < paths.length) {
		if (value == null)
			return defaultReturnValue ? defaultReturnValue : null;

		value = value[paths[idx]];
		idx += 1;
	}

	return defaultReturnValue ? defaultReturnValue : value;
};




export const toLowerCase = value => String(value).toLowerCase();



export const currencyFormatter = (amount, decimalCount = "none", decimal = ",", thousands = ".") => {
	try {
		//decimalCount = Math.abs(decimalCount);
		//decimalCount = isNaN(decimalCount) ? 2 : decimalCount; // se ignora porque necesito pasar un valor raro para manejar que no le fije los decimales... si tienes una mejor solucion chevere modifica

		const negativeSign = amount < 0 ? "-" : "";

		if(decimalCount === "none"){
			let i = parseInt(amount = Math.abs(Number(amount) || 0)).toString();
			let j = (i.length > 3) ? i.length % 3 : 0;
			let decimalPart = (Math.abs(amount - i) !== 0 ? decimal + String(Math.abs(amount - i)).slice(2) : '');

			return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + ( decimalPart.length ? decimalPart.slice(0, 8) : '' );
		}
		else{
			let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
			let j = (i.length > 3) ? i.length % 3 : 0;

			return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
		}
	} catch (e) {
		console.log(e)
	}
}

export const RIFmask = userInput => {

	let numbers = userInput.match(/\d/g);
	let numberLength = 0;
	if (numbers) {
		numberLength = numbers.join("").length;
	}

	/*
	 * A rif number can have from 7 to 9 digits before the last one,
	 * plus the check digit wich is a precalculated digit from some SENIAT established operations
	 *
	 *	La letra“ V”: Para Venezolanos.
	 *	La letra“ E”: Para Extranjeros con cédula de identidad.
	 *	La letra“ P”: Personas Naturales extranjeras sin cédula de identidad.Siendo su identificación el número del pasaporte.
	 *	La letra“ G”: Entes Gubernamentales, la cual incluye entidades municipales, ministerios y otros organismos del Estado.
	 *	La letra“ J”: Contribuyentes Jurídicos, es decir, empresas con registros mercantiles.
	 *	La letra“ C”: Consejos Comunales, Comunas y todas las organizaciones del Poder Popular.
	 */
	if (numberLength > 9) {
		return [/[VEJPG]/i, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/];

	} else if (numberLength === 9) {
		return [/[VEJPG]/i, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/];

	} else {
		return [/[VEJPG]/i, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/];
	}
}