
const initialState = {
	data : {
		tasa : '',
		amount_crypto : '',
		amount_currency : '',
		wallet_id : '',
		address_id : ''
	},
	currency : 1,
	currencys : [],
	address_liquidation : [],
	liquidations: {
		"current_page": 1,
		"data": [],
		"first_page_url": "",
		"from": 1,
		"last_page": 1,
		"last_page_url": "",
		"next_page_url": null,
		"path": "",
		"per_page": 10,
		"prev_page_url": null,
		"to": 1,
		"total": 1
	},
	liquidation : null,
	admin_wallet : [],
	btc_eur: 0,
	btc_usd: 0,
	btc_bss: 0,
	dash_eur: 0,
	dash_usd: 0,
	dash_bss: null,
	wallets: null,
	post_status: false,
	error_address : '',
	tasa_banck : 0,
	balance: 0,
	balance_dash: 0,
	ad_id: null,
	payBtc: false,
	valGetLiquidation: false
};

const withdraw = (state = initialState, action) => {
	switch (action.type) {
		case 'FETCH_WITHDRAW':
			return {...state, data : action.data };

		case 'FETCH_CURRENCYS':
			return { ...state, currencys : action.currencys };

		case 'FETCH_ADDRESS_LIQUIDATION':
			return { ...state, address_liquidation : action.address_liquidation };

		case 'FETCH_LIQUIDATIONS':
			return { ...state, liquidations : action.liquidations };

		case 'FETCH_LIQUIDATION':
			return { ...state, liquidation : action.liquidation };

		case 'FETCH_ADMIN_WALLET':
			return { ...state, admin_wallet : action.admin_wallet };

		case 'FETCH_BTC_EUR':
			return { ...state, btc_eur : action.btc_eur };

		case 'FETCH_BTC_USD':
			return { ...state, btc_usd : action.btc_usd };

		case 'FETCH_BTC_BSS':
			return { ...state, btc_bss : action.btc_bss };

		case 'FETCH_DASH_EUR':
			return { ...state, dash_eur : action.dash_eur };

		case 'FETCH_DASH_USD':
			return { ...state, dash_usd : action.dash_usd };

		case 'FETCH_DASH_BSS':
			return { ...state, dash_bss : action.dash_bss };

		case 'FETCH_WITHDRAW_WALLETS':
			return { ...state, wallets : action.wallets };

		case 'FETCH_POST_STATUS':
			return { ...state, post_status : action.post_status };

		case 'FETCH_ERROR_ADDRESS':
			return { ...state, error_address : action.error_address };

		case 'FETCH_TASA_BANCK':
			return { ...state, tasa_banck : action.tasa_banck };

		case 'FETCH_BALANCE':
			return { ...state, balance : action.balance };

		case 'FETCH_BALANCE_DASH':
			return { ...state, balance_dash : action.balance_dash };

		case 'FETCH_AD_ID':
			return { ...state, ad_id : action.ad_id };

		case 'FETCH_PAYBTC':
			return { ...state, payBtc : action.payBtc };

		case 'FETCH_VALGETLIGUIDATION':
			return { ...state, valGetLiquidation : action.valGetLiquidation };

		default:
			return state;
	}
};

export default withdraw;
