import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import './scss/style.scss';

// Containers
import { DefaultLayout } from './containers';
// Pages
import { Login, Page404, Page500, Register } from './views';
import ForgotPassword from './views/Register/ForgotPassword';
import NewPasswordForm from './views/Register/NewPasswordForm';
import Confirmation from './views/Register/Confirmation.jsx';

// import { renderRoutes } from 'react-router-config';

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route { ...rest } render={
     (props) => 
     (sessionStorage.getItem('user') && sessionStorage.getItem('user') !== null ) ? 
        (<Component {...props} />)
      : 
        (<Redirect to={{ pathname: "/login", state: { from: props.location }}}/>)
    } />
);

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" name="Login Page" component={Login} />
          <Route exact path="/register" name="Register Page" component={Register} />
          <Route exact path="/404" name="Page 404" component={Page404} />
          <Route exact path="/500" name="Page 500" component={Page500} />
          <Route exact path="/password-reset" name='' component={ ForgotPassword } />
          <Route exact path="/password-reset/:token" name='' component={ NewPasswordForm } />
          <Route exact path="/confirmation/:token" name='' component={ Confirmation } />
          <PrivateRoute path="/" component={ DefaultLayout } />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
