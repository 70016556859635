import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody, Col, Container, Input, FormGroup, Row, Label } from 'reactstrap';
import { Notification, Preloader } from '../Plugins/';
import { passwordForgotten } from '../../store/actions/userActions';
import logo_login from '../../assets/img/brand/logo_login.png';
import email_key from '../../assets/img/email_key.svg';
import { clear, has, get, vanish } from '../../store/actions/notificationsActions';
import { Link } from 'react-router-dom';

class ForgotPassword extends Component {
	constructor(props){
		super(props);

		this.state = {
			email: '',
			errorEmail: '',
			client_url: window.location.href,  /* xHACER: usar la ruta q me de react-router */
			valid_token: undefined,
			token: ''
		}

		this.props.vanish()
	}

	componentDidMount(){
		this.setState(prevState => ({
			...prevState,
			valid_token: !prevState.valid_token
		}))
	}

	onChange = (target) => {
		const { name, value } = target;
		this.setState(prevState => ({
			...prevState,
			[name]: value
		}));
	};

	onChange = (target, validation = null) => {
		let { name, value } = target;
		let val = false;

		if(validation != null){
			if(value !== ""){
				if( validation.test( value ) ){
					val = true;
				}
			} else {
				val = true;
			}
		} else {
			val = true;
		}

		if(val){
			if(name === 'email'){
                let classError = '';
                if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test( value )){
                    classError = 'input-error';
                } else {
                    classError = '';
                }
                this.setState(prevState => ({
                    ...prevState,
                    errorEmail: classError
                }));
			}

			this.setState(prevState => ({
				...prevState,
				[name]: value
			}));
		}
	};

	showMailMessage(){
		return (
			<CardBody className="confirm-email align-items-center">
				<div className="text-center mb-2"><img src={logo_login} width="120" alt="logo"/></div>
				<hr className="mb-4" />
				<h2>Tranquilo!</h2>
				<p className="text-muted">No te preocupes, obtendrás una nueva contraseña.</p>
				<p className="text-muted"> Por favor confirma tu correo y presiona el enlace  enviado para crear tu nueva contraseña</p>
				<img className="img_center w_100" src={email_key} alt="pw_forget"/>
			</CardBody>
		)
	}

	render(){
		document.body.setAttribute("class", "democlass");

		return (
			<div className="app flex-row align-items-center">
					<Container>
						<Row style={{"marginTop": "-100px"}} className="justify-content-center">
							<Col md="5" sm="12" className="login">
								<Card className="p-3">
									{ !this.props.valid_token && (
										<CardBody onKeyDown={ (e) => this.props.clear(e.target.name) }>
											<div className="text-center mb-2"><img src={logo_login} width="120" alt="logo" /></div>
											<hr className="mb-4" />
											<h2>Recupera tu cuenta</h2>
											<p className="text-muted">Escribe tu correo electrónico y te enviaremos un email para iniciar sesión</p>
											<FormGroup className="mb-4">
												<Label>Correo</Label>
												<Input type="text" name="email" className={this.state.errorEmail} placeholder="Correo" onChange={ (e) => this.onChange(e.target) } />
                                                { this.props.has('email') ? <span className="help-block text-danger">{ this.props.get('email') }</span> : '' }
											</FormGroup>
											<Row className="footer_login">
												<Col sm="12" md="12" lg="6" className="text-left">
													<Link to="/login">
														<Button color="link" className="px-0"> 
															Iniciar sesión
														</Button>
													</Link>
												</Col>
												<Col sm="12" md="12" lg="6">
													<Button color="primary" className="px-5 right" onClick={() => this.props.passwordForgotten(this.state)}> 
														Enviar
													</Button>
												</Col>
											</Row>
										</CardBody>
									)}
									{ (this.props.valid_token && !!!this.state.token) && this.showMailMessage() }
								</Card>
							</Col>
						</Row>
					</Container>
					<Notification />
					<Preloader />
				</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		notification: state.notificationReducer,
		valid_token: state.clientReducer.valid_token,
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		passwordForgotten: (data) => dispatch(passwordForgotten(data)),
		clear: (field) => dispatch(clear(field)),
		has: (field, prefix) => dispatch(has(field, prefix)),
		get: (field) => dispatch(get(field)),
		vanish: () => dispatch(vanish()),
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
