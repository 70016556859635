import React, { Component } from 'react';
import { Alert, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

class FontAwesome extends Component {
  render() {
    return (
      <div className="animated fadeIn">
        <Card id="new47">
          <CardHeader>
            <i className="fa fa-font-awesome"></i> 55 New Icons in 4.7
          </CardHeader>
          <CardBody>
            <Row className="text-center">
              <Col className="fa-hover" xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-address-book fa-lg mt-4"></i><br />address-book
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-address-book-o fa-lg mt-4"></i><br />address-book-o
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-address-card fa-lg mt-4"></i><br />address-card
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-address-card-o fa-lg mt-4"></i><br />address-card-o
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bandcamp fa-lg mt-4"></i><br />bandcamp
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bath fa-lg mt-4"></i><br />bath
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bathtub fa-lg mt-4"></i><br />bathtub <span className="text-muted">(alias)</span>
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-drivers-license fa-lg mt-4"></i><br />drivers-license <span className="text-muted">(alias)</span>
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-drivers-license-o fa-lg mt-4"></i><br />drivers-license-o <span className="text-muted">(alias)</span>
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-eercast fa-lg mt-4"></i><br />eercast
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-envelope-open fa-lg mt-4"></i><br />envelope-open
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-envelope-open-o fa-lg mt-4"></i><br />envelope-open-o
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-etsy fa-lg mt-4"></i><br />etsy
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-free-code-camp fa-lg mt-4"></i><br />free-code-camp
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-grav fa-lg mt-4"></i><br />grav
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-handshake-o fa-lg mt-4"></i><br />handshake-o
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-id-badge fa-lg mt-4"></i><br />id-badge
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-id-card fa-lg mt-4"></i><br />id-card
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-id-card-o fa-lg mt-4"></i><br />id-card-o
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-imdb fa-lg mt-4"></i><br />imdb
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-linode fa-lg mt-4"></i><br />linode
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-meetup fa-lg mt-4"></i><br />meetup
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-microchip fa-lg mt-4"></i><br />microchip
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-podcast fa-lg mt-4"></i><br />podcast
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-quora fa-lg mt-4"></i><br />quora
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-ravelry fa-lg mt-4"></i><br />ravelry
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-s15 fa-lg mt-4"></i><br />s15 <span className="text-muted">(alias)</span>
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-shower fa-lg mt-4"></i><br />shower
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-snowflake-o fa-lg mt-4"></i><br />snowflake-o
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-superpowers fa-lg mt-4"></i><br />superpowers
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-telegram fa-lg mt-4"></i><br />telegram
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-thermometer fa-lg mt-4"></i><br />thermometer <span className="text-muted">(alias)</span>
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-thermometer-0 fa-lg mt-4"></i><br />thermometer-0 <span className="text-muted">(alias)</span>
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-thermometer-1 fa-lg mt-4"></i><br />thermometer-1 <span className="text-muted">(alias)</span>
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-thermometer-2 fa-lg mt-4"></i><br />thermometer-2 <span className="text-muted">(alias)</span>
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-thermometer-3 fa-lg mt-4"></i><br />thermometer-3 <span className="text-muted">(alias)</span>
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-thermometer-4 fa-lg mt-4"></i><br />thermometer-4 <span className="text-muted">(alias)</span>
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-thermometer-empty fa-lg mt-4"></i><br />thermometer-empty
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-thermometer-full fa-lg mt-4"></i><br />thermometer-full
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-thermometer-half fa-lg mt-4"></i><br />thermometer-half
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-thermometer-quarter fa-lg mt-4"></i><br />thermometer-quarter
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-thermometer-three-quarters fa-lg mt-4"></i><br />thermometer-three-quarters
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-times-rectangle fa-lg mt-4"></i><br />times-rectangle <span className="text-muted">(alias)</span>
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-times-rectangle-o fa-lg mt-4"></i><br />times-rectangle-o <span className="text-muted">(alias)</span>
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-user-circle fa-lg mt-4"></i><br />user-circle
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-user-circle-o fa-lg mt-4"></i><br />user-circle-o
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-user-o fa-lg mt-4"></i><br />user-o
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-vcard fa-lg mt-4"></i><br />vcard <span className="text-muted">(alias)</span>
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-vcard-o fa-lg mt-4"></i><br />vcard-o <span className="text-muted">(alias)</span>
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-window-close fa-lg mt-4"></i><br />window-close
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-window-close-o fa-lg mt-4"></i><br />window-close-o
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-window-maximize fa-lg mt-4"></i><br />window-maximize
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-window-minimize fa-lg mt-4"></i><br />window-minimize
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-window-restore fa-lg mt-4"></i><br />window-restore
              </Col>
              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-wpexplorer fa-lg mt-4"></i><br />wpexplorer
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card id="new">
          <CardHeader>20 New Icons in 4.5</CardHeader>
          <CardBody>
            <Row className="text-center">

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bluetooth fa-lg mt-4"></i><br />bluetooth
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bluetooth-b fa-lg mt-4"></i><br />bluetooth-b
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-codiepie fa-lg mt-4"></i><br />codiepie
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-credit-card-alt fa-lg mt-4"></i><br />credit-card-alt
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-edge fa-lg mt-4"></i><br />edge
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-fort-awesome fa-lg mt-4"></i><br />fort-awesome
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hashtag fa-lg mt-4"></i><br />hashtag
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-mixcloud fa-lg mt-4"></i><br />mixcloud
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-modx fa-lg mt-4"></i><br />modx
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-pause-circle fa-lg mt-4"></i><br />pause-circle
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-pause-circle-o fa-lg mt-4"></i><br />pause-circle-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-percent fa-lg mt-4"></i><br />percent
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-product-hunt fa-lg mt-4"></i><br />product-hunt
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-reddit-alien fa-lg mt-4"></i><br />reddit-alien
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-scribd fa-lg mt-4"></i><br />scribd
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-shopping-bag fa-lg mt-4"></i><br />shopping-bag
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-shopping-basket fa-lg mt-4"></i><br />shopping-basket
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-stop-circle fa-lg mt-4"></i><br />stop-circle
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-stop-circle-o fa-lg mt-4"></i><br />stop-circle-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-usb fa-lg mt-4"></i><br />usb
              </Col>

            </Row>

          </CardBody>
        </Card>

        <Card id="web-application">
          <CardHeader>Web Application Icons</CardHeader>
          <CardBody>
            <Row className="text-center">

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-adjust fa-lg mt-4"></i><br />adjust
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-anchor fa-lg mt-4"></i><br />anchor
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-archive fa-lg mt-4"></i><br />archive
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-area-chart fa-lg mt-4"></i><br />area-chart
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-arrows fa-lg mt-4"></i><br />arrows
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-arrows-h fa-lg mt-4"></i><br />arrows-h
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-arrows-v fa-lg mt-4"></i><br />arrows-v
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-asterisk fa-lg mt-4"></i><br />asterisk
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-at fa-lg mt-4"></i><br />at
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-automobile fa-lg mt-4"></i><br />automobile <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-balance-scale fa-lg mt-4"></i><br />balance-scale
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-ban fa-lg mt-4"></i><br />ban
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bank fa-lg mt-4"></i><br />bank <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bar-chart fa-lg mt-4"></i><br />bar-chart
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bar-chart-o fa-lg mt-4"></i><br />bar-chart-o <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-barcode fa-lg mt-4"></i><br />barcode
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bars fa-lg mt-4"></i><br />bars
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-battery-0 fa-lg mt-4"></i><br />battery-0 <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-battery-1 fa-lg mt-4"></i><br />battery-1 <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-battery-2 fa-lg mt-4"></i><br />battery-2 <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-battery-3 fa-lg mt-4"></i><br />battery-3 <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-battery-4 fa-lg mt-4"></i><br />battery-4 <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-battery-empty fa-lg mt-4"></i><br />battery-empty
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-battery-full fa-lg mt-4"></i><br />battery-full
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-battery-half fa-lg mt-4"></i><br />battery-half
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-battery-quarter fa-lg mt-4"></i><br />battery-quarter
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-battery-three-quarters fa-lg mt-4"></i><br />battery-three-quarters
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bed fa-lg mt-4"></i><br />bed
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-beer fa-lg mt-4"></i><br />beer
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bell fa-lg mt-4"></i><br />bell
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bell-o fa-lg mt-4"></i><br />bell-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bell-slash fa-lg mt-4"></i><br />bell-slash
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bell-slash-o fa-lg mt-4"></i><br />bell-slash-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bicycle fa-lg mt-4"></i><br />bicycle
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-binoculars fa-lg mt-4"></i><br />binoculars
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-birthday-cake fa-lg mt-4"></i><br />birthday-cake
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bluetooth fa-lg mt-4"></i><br />bluetooth
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bluetooth-b fa-lg mt-4"></i><br />bluetooth-b
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bolt fa-lg mt-4"></i><br />bolt
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bomb fa-lg mt-4"></i><br />bomb
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-book fa-lg mt-4"></i><br />book
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bookmark fa-lg mt-4"></i><br />bookmark
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bookmark-o fa-lg mt-4"></i><br />bookmark-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-briefcase fa-lg mt-4"></i><br />briefcase
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bug fa-lg mt-4"></i><br />bug
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-building fa-lg mt-4"></i><br />building
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-building-o fa-lg mt-4"></i><br />building-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bullhorn fa-lg mt-4"></i><br />bullhorn
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bullseye fa-lg mt-4"></i><br />bullseye
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bus fa-lg mt-4"></i><br />bus
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-cab fa-lg mt-4"></i><br />cab <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-calculator fa-lg mt-4"></i><br />calculator
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-calendar fa-lg mt-4"></i><br />calendar
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-calendar-check-o fa-lg mt-4"></i><br />calendar-check-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-calendar-minus-o fa-lg mt-4"></i><br />calendar-minus-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-calendar-o fa-lg mt-4"></i><br />calendar-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-calendar-plus-o fa-lg mt-4"></i><br />calendar-plus-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-calendar-times-o fa-lg mt-4"></i><br />calendar-times-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-camera fa-lg mt-4"></i><br />camera
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-camera-retro fa-lg mt-4"></i><br />camera-retro
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-car fa-lg mt-4"></i><br />car
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-caret-square-o-down fa-lg mt-4"></i><br />caret-square-o-down
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-caret-square-o-left fa-lg mt-4"></i><br />caret-square-o-left
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-caret-square-o-right fa-lg mt-4"></i><br />caret-square-o-right
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-caret-square-o-up fa-lg mt-4"></i><br />caret-square-o-up
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-cart-arrow-down fa-lg mt-4"></i><br />cart-arrow-down
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-cart-plus fa-lg mt-4"></i><br />cart-plus
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-cc fa-lg mt-4"></i><br />cc
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-certificate fa-lg mt-4"></i><br />certificate
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-check fa-lg mt-4"></i><br />check
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-check-circle fa-lg mt-4"></i><br />check-circle
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-check-circle-o fa-lg mt-4"></i><br />check-circle-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-check-square fa-lg mt-4"></i><br />check-square
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-check-square-o fa-lg mt-4"></i><br />check-square-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-child fa-lg mt-4"></i><br />child
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-circle fa-lg mt-4"></i><br />circle
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-circle-o fa-lg mt-4"></i><br />circle-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-circle-o-notch fa-lg mt-4"></i><br />circle-o-notch
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-circle-thin fa-lg mt-4"></i><br />circle-thin
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-clock-o fa-lg mt-4"></i><br />clock-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-clone fa-lg mt-4"></i><br />clone
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-close fa-lg mt-4"></i><br />close <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-cloud fa-lg mt-4"></i><br />cloud
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-cloud-download fa-lg mt-4"></i><br />cloud-download
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-cloud-upload fa-lg mt-4"></i><br />cloud-upload
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-code fa-lg mt-4"></i><br />code
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-code-fork fa-lg mt-4"></i><br />code-fork
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-coffee fa-lg mt-4"></i><br />coffee
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-cog fa-lg mt-4"></i><br />cog
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-cogs fa-lg mt-4"></i><br />cogs
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-comment fa-lg mt-4"></i><br />comment
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-comment-o fa-lg mt-4"></i><br />comment-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-commenting fa-lg mt-4"></i><br />commenting
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-commenting-o fa-lg mt-4"></i><br />commenting-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-comments fa-lg mt-4"></i><br />comments
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-comments-o fa-lg mt-4"></i><br />comments-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-compass fa-lg mt-4"></i><br />compass
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-copyright fa-lg mt-4"></i><br />copyright
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-creative-commons fa-lg mt-4"></i><br />creative-commons
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-credit-card fa-lg mt-4"></i><br />credit-card
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-credit-card-alt fa-lg mt-4"></i><br />credit-card-alt
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-crop fa-lg mt-4"></i><br />crop
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-crosshairs fa-lg mt-4"></i><br />crosshairs
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-cube fa-lg mt-4"></i><br />cube
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-cubes fa-lg mt-4"></i><br />cubes
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-cutlery fa-lg mt-4"></i><br />cutlery
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-dashboard fa-lg mt-4"></i><br />dashboard <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-database fa-lg mt-4"></i><br />database
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-desktop fa-lg mt-4"></i><br />desktop
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-diamond fa-lg mt-4"></i><br />diamond
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-dot-circle-o fa-lg mt-4"></i><br />dot-circle-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-download fa-lg mt-4"></i><br />download
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-edit fa-lg mt-4"></i><br />edit <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-ellipsis-h fa-lg mt-4"></i><br />ellipsis-h
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-ellipsis-v fa-lg mt-4"></i><br />ellipsis-v
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-envelope fa-lg mt-4"></i><br />envelope
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-envelope-o fa-lg mt-4"></i><br />envelope-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-envelope-square fa-lg mt-4"></i><br />envelope-square
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-eraser fa-lg mt-4"></i><br />eraser
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-exchange fa-lg mt-4"></i><br />exchange
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-exclamation fa-lg mt-4"></i><br />exclamation
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-exclamation-circle fa-lg mt-4"></i><br />exclamation-circle
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-exclamation-triangle fa-lg mt-4"></i><br />exclamation-triangle
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-external-link fa-lg mt-4"></i><br />external-link
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-external-link-square fa-lg mt-4"></i><br />external-link-square
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-eye fa-lg mt-4"></i><br />eye
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-eye-slash fa-lg mt-4"></i><br />eye-slash
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-eyedropper fa-lg mt-4"></i><br />eyedropper
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-fax fa-lg mt-4"></i><br />fax
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-feed fa-lg mt-4"></i><br />feed <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-female fa-lg mt-4"></i><br />female
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-fighter-jet fa-lg mt-4"></i><br />fighter-jet
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file-archive-o fa-lg mt-4"></i><br />file-archive-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file-audio-o fa-lg mt-4"></i><br />file-audio-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file-code-o fa-lg mt-4"></i><br />file-code-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file-excel-o fa-lg mt-4"></i><br />file-excel-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file-image-o fa-lg mt-4"></i><br />file-image-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file-movie-o fa-lg mt-4"></i><br />file-movie-o <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file-pdf-o fa-lg mt-4"></i><br />file-pdf-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file-photo-o fa-lg mt-4"></i><br />file-photo-o <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file-picture-o fa-lg mt-4"></i><br />file-picture-o <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file-powerpoint-o fa-lg mt-4"></i><br />file-powerpoint-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file-sound-o fa-lg mt-4"></i><br />file-sound-o <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file-video-o fa-lg mt-4"></i><br />file-video-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file-word-o fa-lg mt-4"></i><br />file-word-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file-zip-o fa-lg mt-4"></i><br />file-zip-o <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-film fa-lg mt-4"></i><br />film
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-filter fa-lg mt-4"></i><br />filter
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-fire fa-lg mt-4"></i><br />fire
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-fire-extinguisher fa-lg mt-4"></i><br />fire-extinguisher
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-flag fa-lg mt-4"></i><br />flag
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-flag-checkered fa-lg mt-4"></i><br />flag-checkered
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-flag-o fa-lg mt-4"></i><br />flag-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-flash fa-lg mt-4"></i><br />flash <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-flask fa-lg mt-4"></i><br />flask
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-folder fa-lg mt-4"></i><br />folder
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-folder-o fa-lg mt-4"></i><br />folder-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-folder-open fa-lg mt-4"></i><br />folder-open
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-folder-open-o fa-lg mt-4"></i><br />folder-open-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-frown-o fa-lg mt-4"></i><br />frown-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-futbol-o fa-lg mt-4"></i><br />futbol-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-gamepad fa-lg mt-4"></i><br />gamepad
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-gavel fa-lg mt-4"></i><br />gavel
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-gear fa-lg mt-4"></i><br />gear <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-gears fa-lg mt-4"></i><br />gears <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-gift fa-lg mt-4"></i><br />gift
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-glass fa-lg mt-4"></i><br />glass
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-globe fa-lg mt-4"></i><br />globe
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-graduation-cap fa-lg mt-4"></i><br />graduation-cap
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-group fa-lg mt-4"></i><br />group <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hand-grab-o fa-lg mt-4"></i><br />hand-grab-o <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hand-lizard-o fa-lg mt-4"></i><br />hand-lizard-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hand-paper-o fa-lg mt-4"></i><br />hand-paper-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hand-peace-o fa-lg mt-4"></i><br />hand-peace-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hand-pointer-o fa-lg mt-4"></i><br />hand-pointer-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hand-rock-o fa-lg mt-4"></i><br />hand-rock-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hand-scissors-o fa-lg mt-4"></i><br />hand-scissors-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hand-spock-o fa-lg mt-4"></i><br />hand-spock-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hand-stop-o fa-lg mt-4"></i><br />hand-stop-o <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hashtag fa-lg mt-4"></i><br />hashtag
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hdd-o fa-lg mt-4"></i><br />hdd-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-headphones fa-lg mt-4"></i><br />headphones
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-heart fa-lg mt-4"></i><br />heart
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-heart-o fa-lg mt-4"></i><br />heart-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-heartbeat fa-lg mt-4"></i><br />heartbeat
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-history fa-lg mt-4"></i><br />history
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-home fa-lg mt-4"></i><br />home
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hotel fa-lg mt-4"></i><br />hotel <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hourglass fa-lg mt-4"></i><br />hourglass
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hourglass-1 fa-lg mt-4"></i><br />hourglass-1 <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hourglass-2 fa-lg mt-4"></i><br />hourglass-2 <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hourglass-3 fa-lg mt-4"></i><br />hourglass-3 <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hourglass-end fa-lg mt-4"></i><br />hourglass-end
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hourglass-half fa-lg mt-4"></i><br />hourglass-half
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hourglass-o fa-lg mt-4"></i><br />hourglass-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hourglass-start fa-lg mt-4"></i><br />hourglass-start
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-i-cursor fa-lg mt-4"></i><br />i-cursor
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-image fa-lg mt-4"></i><br />image <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-inbox fa-lg mt-4"></i><br />inbox
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-industry fa-lg mt-4"></i><br />industry
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-info fa-lg mt-4"></i><br />info
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-info-circle fa-lg mt-4"></i><br />info-circle
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-institution fa-lg mt-4"></i><br />institution <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-key fa-lg mt-4"></i><br />key
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-keyboard-o fa-lg mt-4"></i><br />keyboard-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-language fa-lg mt-4"></i><br />language
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-laptop fa-lg mt-4"></i><br />laptop
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-leaf fa-lg mt-4"></i><br />leaf
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-legal fa-lg mt-4"></i><br />legal <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-lemon-o fa-lg mt-4"></i><br />lemon-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-level-down fa-lg mt-4"></i><br />level-down
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-level-up fa-lg mt-4"></i><br />level-up
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-life-bouy fa-lg mt-4"></i><br />life-bouy <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-life-buoy fa-lg mt-4"></i><br />life-buoy <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-life-ring fa-lg mt-4"></i><br />life-ring
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-life-saver fa-lg mt-4"></i><br />life-saver <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-lightbulb-o fa-lg mt-4"></i><br />lightbulb-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-line-chart fa-lg mt-4"></i><br />line-chart
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-location-arrow fa-lg mt-4"></i><br />location-arrow
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-lock fa-lg mt-4"></i><br />lock
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-magic fa-lg mt-4"></i><br />magic
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-magnet fa-lg mt-4"></i><br />magnet
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-mail-forward fa-lg mt-4"></i><br />mail-forward <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-mail-reply fa-lg mt-4"></i><br />mail-reply <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-mail-reply-all fa-lg mt-4"></i><br />mail-reply-all <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-male fa-lg mt-4"></i><br />male
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-map fa-lg mt-4"></i><br />map
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-map-marker fa-lg mt-4"></i><br />map-marker
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-map-o fa-lg mt-4"></i><br />map-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-map-pin fa-lg mt-4"></i><br />map-pin
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-map-signs fa-lg mt-4"></i><br />map-signs
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-meh-o fa-lg mt-4"></i><br />meh-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-microphone fa-lg mt-4"></i><br />microphone
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-microphone-slash fa-lg mt-4"></i><br />microphone-slash
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-minus fa-lg mt-4"></i><br />minus
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-minus-circle fa-lg mt-4"></i><br />minus-circle
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-minus-square fa-lg mt-4"></i><br />minus-square
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-minus-square-o fa-lg mt-4"></i><br />minus-square-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-mobile fa-lg mt-4"></i><br />mobile
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-mobile-phone fa-lg mt-4"></i><br />mobile-phone <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-money fa-lg mt-4"></i><br />money
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-moon-o fa-lg mt-4"></i><br />moon-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-mortar-board fa-lg mt-4"></i><br />mortar-board <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-motorcycle fa-lg mt-4"></i><br />motorcycle
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-mouse-pointer fa-lg mt-4"></i><br />mouse-pointer
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-music fa-lg mt-4"></i><br />music
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-navicon fa-lg mt-4"></i><br />navicon <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-newspaper-o fa-lg mt-4"></i><br />newspaper-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-object-group fa-lg mt-4"></i><br />object-group
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-object-ungroup fa-lg mt-4"></i><br />object-ungroup
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-paint-brush fa-lg mt-4"></i><br />paint-brush
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-paper-plane fa-lg mt-4"></i><br />paper-plane
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-paper-plane-o fa-lg mt-4"></i><br />paper-plane-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-paw fa-lg mt-4"></i><br />paw
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-pencil fa-lg mt-4"></i><br />pencil
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-pencil-square fa-lg mt-4"></i><br />pencil-square
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-pencil-square-o fa-lg mt-4"></i><br />pencil-square-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-percent fa-lg mt-4"></i><br />percent
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-phone fa-lg mt-4"></i><br />phone
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-phone-square fa-lg mt-4"></i><br />phone-square
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-photo fa-lg mt-4"></i><br />photo <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-picture-o fa-lg mt-4"></i><br />picture-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-pie-chart fa-lg mt-4"></i><br />pie-chart
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-plane fa-lg mt-4"></i><br />plane
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-plug fa-lg mt-4"></i><br />plug
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-plus fa-lg mt-4"></i><br />plus
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-plus-circle fa-lg mt-4"></i><br />plus-circle
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-plus-square fa-lg mt-4"></i><br />plus-square
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-plus-square-o fa-lg mt-4"></i><br />plus-square-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-power-off fa-lg mt-4"></i><br />power-off
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-print fa-lg mt-4"></i><br />print
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-puzzle-piece fa-lg mt-4"></i><br />puzzle-piece
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-qrcode fa-lg mt-4"></i><br />qrcode
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-question fa-lg mt-4"></i><br />question
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-question-circle fa-lg mt-4"></i><br />question-circle
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-quote-left fa-lg mt-4"></i><br />quote-left
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-quote-right fa-lg mt-4"></i><br />quote-right
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-random fa-lg mt-4"></i><br />random
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-recycle fa-lg mt-4"></i><br />recycle
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-refresh fa-lg mt-4"></i><br />refresh
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-registered fa-lg mt-4"></i><br />registered
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-remove fa-lg mt-4"></i><br />remove <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-reorder fa-lg mt-4"></i><br />reorder <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-reply fa-lg mt-4"></i><br />reply
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-reply-all fa-lg mt-4"></i><br />reply-all
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-retweet fa-lg mt-4"></i><br />retweet
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-road fa-lg mt-4"></i><br />road
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-rocket fa-lg mt-4"></i><br />rocket
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-rss fa-lg mt-4"></i><br />rss
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-rss-square fa-lg mt-4"></i><br />rss-square
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-search fa-lg mt-4"></i><br />search
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-search-minus fa-lg mt-4"></i><br />search-minus
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-search-plus fa-lg mt-4"></i><br />search-plus
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-send fa-lg mt-4"></i><br />send <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-send-o fa-lg mt-4"></i><br />send-o <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-server fa-lg mt-4"></i><br />server
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-share fa-lg mt-4"></i><br />share
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-share-alt fa-lg mt-4"></i><br />share-alt
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-share-alt-square fa-lg mt-4"></i><br />share-alt-square
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-share-square fa-lg mt-4"></i><br />share-square
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-share-square-o fa-lg mt-4"></i><br />share-square-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-shield fa-lg mt-4"></i><br />shield
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-ship fa-lg mt-4"></i><br />ship
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-shopping-bag fa-lg mt-4"></i><br />shopping-bag
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-shopping-basket fa-lg mt-4"></i><br />shopping-basket
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-shopping-cart fa-lg mt-4"></i><br />shopping-cart
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-sign-in fa-lg mt-4"></i><br />sign-in
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-sign-out fa-lg mt-4"></i><br />sign-out
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-signal fa-lg mt-4"></i><br />signal
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-sitemap fa-lg mt-4"></i><br />sitemap
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-sliders fa-lg mt-4"></i><br />sliders
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-smile-o fa-lg mt-4"></i><br />smile-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-soccer-ball-o fa-lg mt-4"></i><br />soccer-ball-o <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-sort fa-lg mt-4"></i><br />sort
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-sort-alpha-asc fa-lg mt-4"></i><br />sort-alpha-asc
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-sort-alpha-desc fa-lg mt-4"></i><br />sort-alpha-desc
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-sort-amount-asc fa-lg mt-4"></i><br />sort-amount-asc
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-sort-amount-desc fa-lg mt-4"></i><br />sort-amount-desc
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-sort-asc fa-lg mt-4"></i><br />sort-asc
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-sort-desc fa-lg mt-4"></i><br />sort-desc
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-sort-down fa-lg mt-4"></i><br />sort-down <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-sort-numeric-asc fa-lg mt-4"></i><br />sort-numeric-asc
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-sort-numeric-desc fa-lg mt-4"></i><br />sort-numeric-desc
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-sort-up fa-lg mt-4"></i><br />sort-up <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-space-shuttle fa-lg mt-4"></i><br />space-shuttle
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-spinner fa-lg mt-4"></i><br />spinner
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-spoon fa-lg mt-4"></i><br />spoon
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-square fa-lg mt-4"></i><br />square
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-square-o fa-lg mt-4"></i><br />square-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-star fa-lg mt-4"></i><br />star
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-star-half fa-lg mt-4"></i><br />star-half
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-star-half-empty fa-lg mt-4"></i><br />star-half-empty <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-star-half-full fa-lg mt-4"></i><br />star-half-full <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-star-half-o fa-lg mt-4"></i><br />star-half-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-star-o fa-lg mt-4"></i><br />star-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-sticky-note fa-lg mt-4"></i><br />sticky-note
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-sticky-note-o fa-lg mt-4"></i><br />sticky-note-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-street-view fa-lg mt-4"></i><br />street-view
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-suitcase fa-lg mt-4"></i><br />suitcase
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-sun-o fa-lg mt-4"></i><br />sun-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-support fa-lg mt-4"></i><br />support <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-tablet fa-lg mt-4"></i><br />tablet
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-tachometer fa-lg mt-4"></i><br />tachometer
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-tag fa-lg mt-4"></i><br />tag
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-tags fa-lg mt-4"></i><br />tags
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-tasks fa-lg mt-4"></i><br />tasks
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-taxi fa-lg mt-4"></i><br />taxi
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-television fa-lg mt-4"></i><br />television
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-terminal fa-lg mt-4"></i><br />terminal
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-thumb-tack fa-lg mt-4"></i><br />thumb-tack
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-thumbs-down fa-lg mt-4"></i><br />thumbs-down
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-thumbs-o-down fa-lg mt-4"></i><br />thumbs-o-down
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-thumbs-o-up fa-lg mt-4"></i><br />thumbs-o-up
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-thumbs-up fa-lg mt-4"></i><br />thumbs-up
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-ticket fa-lg mt-4"></i><br />ticket
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-times fa-lg mt-4"></i><br />times
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-times-circle fa-lg mt-4"></i><br />times-circle
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-times-circle-o fa-lg mt-4"></i><br />times-circle-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-tint fa-lg mt-4"></i><br />tint
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-toggle-down fa-lg mt-4"></i><br />toggle-down <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-toggle-left fa-lg mt-4"></i><br />toggle-left <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-toggle-off fa-lg mt-4"></i><br />toggle-off
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-toggle-on fa-lg mt-4"></i><br />toggle-on
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-toggle-right fa-lg mt-4"></i><br />toggle-right <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-toggle-up fa-lg mt-4"></i><br />toggle-up <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-trademark fa-lg mt-4"></i><br />trademark
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-trash fa-lg mt-4"></i><br />trash
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-trash-o fa-lg mt-4"></i><br />trash-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-tree fa-lg mt-4"></i><br />tree
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-trophy fa-lg mt-4"></i><br />trophy
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-truck fa-lg mt-4"></i><br />truck
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-tty fa-lg mt-4"></i><br />tty
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-tv fa-lg mt-4"></i><br />tv <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-umbrella fa-lg mt-4"></i><br />umbrella
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-university fa-lg mt-4"></i><br />university
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-unlock fa-lg mt-4"></i><br />unlock
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-unlock-alt fa-lg mt-4"></i><br />unlock-alt
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-unsorted fa-lg mt-4"></i><br />unsorted <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-upload fa-lg mt-4"></i><br />upload
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-user fa-lg mt-4"></i><br />user
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-user-plus fa-lg mt-4"></i><br />user-plus
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-user-secret fa-lg mt-4"></i><br />user-secret
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-user-times fa-lg mt-4"></i><br />user-times
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-users fa-lg mt-4"></i><br />users
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-video-camera fa-lg mt-4"></i><br />video-camera
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-volume-down fa-lg mt-4"></i><br />volume-down
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-volume-off fa-lg mt-4"></i><br />volume-off
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-volume-up fa-lg mt-4"></i><br />volume-up
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-warning fa-lg mt-4"></i><br />warning <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-wheelchair fa-lg mt-4"></i><br />wheelchair
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-wifi fa-lg mt-4"></i><br />wifi
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-wrench fa-lg mt-4"></i><br />wrench
              </Col>

            </Row>
          </CardBody>

        </Card>

        <Card id="hand">
          <CardHeader>Hand Icons</CardHeader>
          <CardBody>
            <Row className="text-center">

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hand-grab-o fa-lg mt-4"></i><br />hand-grab-o <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hand-lizard-o fa-lg mt-4"></i><br />hand-lizard-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hand-o-down fa-lg mt-4"></i><br />hand-o-down
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hand-o-left fa-lg mt-4"></i><br />hand-o-left
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hand-o-right fa-lg mt-4"></i><br />hand-o-right
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hand-o-up fa-lg mt-4"></i><br />hand-o-up
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hand-paper-o fa-lg mt-4"></i><br />hand-paper-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hand-peace-o fa-lg mt-4"></i><br />hand-peace-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hand-pointer-o fa-lg mt-4"></i><br />hand-pointer-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hand-rock-o fa-lg mt-4"></i><br />hand-rock-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hand-scissors-o fa-lg mt-4"></i><br />hand-scissors-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hand-spock-o fa-lg mt-4"></i><br />hand-spock-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hand-stop-o fa-lg mt-4"></i><br />hand-stop-o <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-thumbs-down fa-lg mt-4"></i><br />thumbs-down
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-thumbs-o-down fa-lg mt-4"></i><br />thumbs-o-down
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-thumbs-o-up fa-lg mt-4"></i><br />thumbs-o-up
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-thumbs-up fa-lg mt-4"></i><br />thumbs-up
              </Col>

            </Row>
          </CardBody>

        </Card>

        <Card id="transportation">
          <CardHeader>Transportation Icons</CardHeader>
          <CardBody>
            <Row className="text-center">

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-ambulance fa-lg mt-4"></i><br />ambulance
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-automobile fa-lg mt-4"></i><br />automobile <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bicycle fa-lg mt-4"></i><br />bicycle
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bus fa-lg mt-4"></i><br />bus
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-cab fa-lg mt-4"></i><br />cab <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-car fa-lg mt-4"></i><br />car
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-fighter-jet fa-lg mt-4"></i><br />fighter-jet
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-motorcycle fa-lg mt-4"></i><br />motorcycle
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-plane fa-lg mt-4"></i><br />plane
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-rocket fa-lg mt-4"></i><br />rocket
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-ship fa-lg mt-4"></i><br />ship
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-space-shuttle fa-lg mt-4"></i><br />space-shuttle
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-subway fa-lg mt-4"></i><br />subway
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-taxi fa-lg mt-4"></i><br />taxi
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-train fa-lg mt-4"></i><br />train
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-truck fa-lg mt-4"></i><br />truck
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-wheelchair fa-lg mt-4"></i><br />wheelchair
              </Col>

            </Row>
          </CardBody>
        </Card>

        <Card id="gender">
          <CardHeader>Gender Icons</CardHeader>
          <CardBody>
            <Row className="text-center">

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-genderless fa-lg mt-4"></i><br />genderless
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-intersex fa-lg mt-4"></i><br />intersex <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-mars fa-lg mt-4"></i><br />mars
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-mars-double fa-lg mt-4"></i><br />mars-double
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-mars-stroke fa-lg mt-4"></i><br />mars-stroke
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-mars-stroke-h fa-lg mt-4"></i><br />mars-stroke-h
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-mars-stroke-v fa-lg mt-4"></i><br />mars-stroke-v
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-mercury fa-lg mt-4"></i><br />mercury
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-neuter fa-lg mt-4"></i><br />neuter
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-transgender fa-lg mt-4"></i><br />transgender
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-transgender-alt fa-lg mt-4"></i><br />transgender-alt
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-venus fa-lg mt-4"></i><br />venus
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-venus-double fa-lg mt-4"></i><br />venus-double
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-venus-mars fa-lg mt-4"></i><br />venus-mars
              </Col>

            </Row>
          </CardBody>
        </Card>

        <Card id="file-type">
          <CardHeader>File Type Icons</CardHeader>
          <CardBody>
            <Row className="text-center">

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file fa-lg mt-4"></i><br />file
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file-archive-o fa-lg mt-4"></i><br />file-archive-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file-audio-o fa-lg mt-4"></i><br />file-audio-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file-code-o fa-lg mt-4"></i><br />file-code-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file-excel-o fa-lg mt-4"></i><br />file-excel-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file-image-o fa-lg mt-4"></i><br />file-image-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file-movie-o fa-lg mt-4"></i><br />file-movie-o <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file-o fa-lg mt-4"></i><br />file-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file-pdf-o fa-lg mt-4"></i><br />file-pdf-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file-photo-o fa-lg mt-4"></i><br />file-photo-o <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file-picture-o fa-lg mt-4"></i><br />file-picture-o <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file-powerpoint-o fa-lg mt-4"></i><br />file-powerpoint-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file-sound-o fa-lg mt-4"></i><br />file-sound-o <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file-text fa-lg mt-4"></i><br />file-text
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file-text-o fa-lg mt-4"></i><br />file-text-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file-video-o fa-lg mt-4"></i><br />file-video-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file-word-o fa-lg mt-4"></i><br />file-word-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file-zip-o fa-lg mt-4"></i><br />file-zip-o <span
                className="text-muted">(alias)</span>
              </Col>

            </Row>
          </CardBody>
        </Card>

        <Card id="spinner">
          <CardHeader>Spinner Icons</CardHeader>
          <CardBody>
            <Alert color="success">
              <ul className="fa-ul">
                <li>
                  <i className="fa fa-info-circle fa-li"></i>
                  These icons work great with the <code>fa-spin</code> class.
                </li>
              </ul>
            </Alert>
            <Row className="text-center">

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-circle-o-notch fa-lg fa-spin mt-4"></i><br />circle-o-notch
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-cog fa-lg mt-4"></i><br />cog
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-gear fa-lg mt-4"></i><br />gear <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-refresh fa-lg mt-4"></i><br />refresh
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-spinner fa-lg mt-4"></i><br />spinner
              </Col>

            </Row>
          </CardBody>
        </Card>

        <Card id="form-control">
          <CardHeader>Form Control Icons</CardHeader>
          <CardBody>
            <Row className="text-center">

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-check-square fa-lg mt-4"></i><br />check-square
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-check-square-o fa-lg mt-4"></i><br />check-square-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-circle fa-lg mt-4"></i><br />circle
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-circle-o fa-lg mt-4"></i><br />circle-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-dot-circle-o fa-lg mt-4"></i><br />dot-circle-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-minus-square fa-lg mt-4"></i><br />minus-square
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-minus-square-o fa-lg mt-4"></i><br />minus-square-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-plus-square fa-lg mt-4"></i><br />plus-square
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-plus-square-o fa-lg mt-4"></i><br />plus-square-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-square fa-lg mt-4"></i><br />square
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-square-o fa-lg mt-4"></i><br />square-o
              </Col>

            </Row>
          </CardBody>
        </Card>

        <Card id="payment">
          <CardHeader>Payment Icons</CardHeader>
          <CardBody>
            <Row className="text-center">

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-cc-amex fa-lg mt-4"></i><br />cc-amex
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-cc-diners-club fa-lg mt-4"></i><br />cc-diners-club
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-cc-discover fa-lg mt-4"></i><br />cc-discover
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-cc-jcb fa-lg mt-4"></i><br />cc-jcb
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-cc-mastercard fa-lg mt-4"></i><br />cc-mastercard
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-cc-paypal fa-lg mt-4"></i><br />cc-paypal
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-cc-stripe fa-lg mt-4"></i><br />cc-stripe
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-cc-visa fa-lg mt-4"></i><br />cc-visa
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-credit-card fa-lg mt-4"></i><br />credit-card
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-credit-card-alt fa-lg mt-4"></i><br />credit-card-alt
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-google-wallet fa-lg mt-4"></i><br />google-wallet
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-paypal fa-lg mt-4"></i><br />paypal
              </Col>

            </Row>
          </CardBody>
        </Card>

        <Card id="chart">
          <CardHeader>Chart Icons</CardHeader>
          <CardBody>
            <Row className="text-center">

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-area-chart fa-lg mt-4"></i><br />area-chart
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bar-chart fa-lg mt-4"></i><br />bar-chart
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bar-chart-o fa-lg mt-4"></i><br />bar-chart-o <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-line-chart fa-lg mt-4"></i><br />line-chart
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-pie-chart fa-lg mt-4"></i><br />pie-chart
              </Col>

            </Row>
          </CardBody>
        </Card>

        <Card id="currency">
          <CardHeader>Currency Icons</CardHeader>
          <CardBody>
            <Row className="text-center">

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bitcoin fa-lg mt-4"></i><br />bitcoin <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-btc fa-lg mt-4"></i><br />btc
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-cny fa-lg mt-4"></i><br />cny <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-dollar fa-lg mt-4"></i><br />dollar <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-eur fa-lg mt-4"></i><br />eur
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-euro fa-lg mt-4"></i><br />euro <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-gbp fa-lg mt-4"></i><br />gbp
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-gg fa-lg mt-4"></i><br />gg
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-gg-circle fa-lg mt-4"></i><br />gg-circle
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-ils fa-lg mt-4"></i><br />ils
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-inr fa-lg mt-4"></i><br />inr
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-jpy fa-lg mt-4"></i><br />jpy
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-krw fa-lg mt-4"></i><br />krw
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-money fa-lg mt-4"></i><br />money
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-rmb fa-lg mt-4"></i><br />rmb <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-rouble fa-lg mt-4"></i><br />rouble <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-rub fa-lg mt-4"></i><br />rub
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-ruble fa-lg mt-4"></i><br />ruble <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-rupee fa-lg mt-4"></i><br />rupee <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-shekel fa-lg mt-4"></i><br />shekel <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-sheqel fa-lg mt-4"></i><br />sheqel <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-try fa-lg mt-4"></i><br />try
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-turkish-lira fa-lg mt-4"></i><br />turkish-lira <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-usd fa-lg mt-4"></i><br />usd
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-won fa-lg mt-4"></i><br />won <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-yen fa-lg mt-4"></i><br />yen <span className="text-muted">(alias)</span>
              </Col>

            </Row>
          </CardBody>
        </Card>

        <Card id="text-editor">
          <CardHeader>Text Editor Icons</CardHeader>
          <CardBody>
            <Row className="text-center">

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-align-center fa-lg mt-4"></i><br />align-center
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-align-justify fa-lg mt-4"></i><br />align-justify
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-align-left fa-lg mt-4"></i><br />align-left
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-align-right fa-lg mt-4"></i><br />align-right
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bold fa-lg mt-4"></i><br />bold
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-chain fa-lg mt-4"></i><br />chain <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-chain-broken fa-lg mt-4"></i><br />chain-broken
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-clipboard fa-lg mt-4"></i><br />clipboard
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-columns fa-lg mt-4"></i><br />columns
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-copy fa-lg mt-4"></i><br />copy <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-cut fa-lg mt-4"></i><br />cut <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-dedent fa-lg mt-4"></i><br />dedent <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-eraser fa-lg mt-4"></i><br />eraser
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file fa-lg mt-4"></i><br />file
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file-o fa-lg mt-4"></i><br />file-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file-text fa-lg mt-4"></i><br />file-text
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-file-text-o fa-lg mt-4"></i><br />file-text-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-files-o fa-lg mt-4"></i><br />files-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-floppy-o fa-lg mt-4"></i><br />floppy-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-font fa-lg mt-4"></i><br />font
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-header fa-lg mt-4"></i><br />header
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-indent fa-lg mt-4"></i><br />indent
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-italic fa-lg mt-4"></i><br />italic
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-link fa-lg mt-4"></i><br />link
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-list fa-lg mt-4"></i><br />list
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-list-alt fa-lg mt-4"></i><br />list-alt
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-list-ol fa-lg mt-4"></i><br />list-ol
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-list-ul fa-lg mt-4"></i><br />list-ul
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-outdent fa-lg mt-4"></i><br />outdent
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-paperclip fa-lg mt-4"></i><br />paperclip
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-paragraph fa-lg mt-4"></i><br />paragraph
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-paste fa-lg mt-4"></i><br />paste <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-repeat fa-lg mt-4"></i><br />repeat
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-rotate-left fa-lg mt-4"></i><br />rotate-left <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-rotate-right fa-lg mt-4"></i><br />rotate-right <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-save fa-lg mt-4"></i><br />save <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-scissors fa-lg mt-4"></i><br />scissors
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-strikethrough fa-lg mt-4"></i><br />strikethrough
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-subscript fa-lg mt-4"></i><br />subscript
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-superscript fa-lg mt-4"></i><br />superscript
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-table fa-lg mt-4"></i><br />table
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-text-height fa-lg mt-4"></i><br />text-height
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-text-width fa-lg mt-4"></i><br />text-width
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-th fa-lg mt-4"></i><br />th
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-th-large fa-lg mt-4"></i><br />th-large
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-th-list fa-lg mt-4"></i><br />th-list
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-underline fa-lg mt-4"></i><br />underline
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-undo fa-lg mt-4"></i><br />undo
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-unlink fa-lg mt-4"></i><br />unlink <span className="text-muted">(alias)</span>
              </Col>

            </Row>
          </CardBody>
        </Card>

        <Card id="directional">
          <CardHeader>Directional Icons</CardHeader>
          <CardBody>
            <Row className="text-center">

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-angle-double-down fa-lg mt-4"></i><br />angle-double-down
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-angle-double-left fa-lg mt-4"></i><br />angle-double-left
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-angle-double-right fa-lg mt-4"></i><br />angle-double-right
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-angle-double-up fa-lg mt-4"></i><br />angle-double-up
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-angle-down fa-lg mt-4"></i><br />angle-down
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-angle-left fa-lg mt-4"></i><br />angle-left
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-angle-right fa-lg mt-4"></i><br />angle-right
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-angle-up fa-lg mt-4"></i><br />angle-up
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-arrow-circle-down fa-lg mt-4"></i><br />arrow-circle-down
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-arrow-circle-left fa-lg mt-4"></i><br />arrow-circle-left
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-arrow-circle-o-down fa-lg mt-4"></i><br />arrow-circle-o-down
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-arrow-circle-o-left fa-lg mt-4"></i><br />arrow-circle-o-left
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-arrow-circle-o-right fa-lg mt-4"></i><br />arrow-circle-o-right
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-arrow-circle-o-up fa-lg mt-4"></i><br />arrow-circle-o-up
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-arrow-circle-right fa-lg mt-4"></i><br />arrow-circle-right
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-arrow-circle-up fa-lg mt-4"></i><br />arrow-circle-up
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-arrow-down fa-lg mt-4"></i><br />arrow-down
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-arrow-left fa-lg mt-4"></i><br />arrow-left
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-arrow-right fa-lg mt-4"></i><br />arrow-right
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-arrow-up fa-lg mt-4"></i><br />arrow-up
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-arrows fa-lg mt-4"></i><br />arrows
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-arrows-alt fa-lg mt-4"></i><br />arrows-alt
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-arrows-h fa-lg mt-4"></i><br />arrows-h
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-arrows-v fa-lg mt-4"></i><br />arrows-v
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-caret-down fa-lg mt-4"></i><br />caret-down
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-caret-left fa-lg mt-4"></i><br />caret-left
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-caret-right fa-lg mt-4"></i><br />caret-right
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-caret-square-o-down fa-lg mt-4"></i><br />caret-square-o-down
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-caret-square-o-left fa-lg mt-4"></i><br />caret-square-o-left
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-caret-square-o-right fa-lg mt-4"></i><br />caret-square-o-right
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-caret-square-o-up fa-lg mt-4"></i><br />caret-square-o-up
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-caret-up fa-lg mt-4"></i><br />caret-up
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-chevron-circle-down fa-lg mt-4"></i><br />chevron-circle-down
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-chevron-circle-left fa-lg mt-4"></i><br />chevron-circle-left
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-chevron-circle-right fa-lg mt-4"></i><br />chevron-circle-right
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-chevron-circle-up fa-lg mt-4"></i><br />chevron-circle-up
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-chevron-down fa-lg mt-4"></i><br />chevron-down
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-chevron-left fa-lg mt-4"></i><br />chevron-left
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-chevron-right fa-lg mt-4"></i><br />chevron-right
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-chevron-up fa-lg mt-4"></i><br />chevron-up
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-exchange fa-lg mt-4"></i><br />exchange
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hand-o-down fa-lg mt-4"></i><br />hand-o-down
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hand-o-left fa-lg mt-4"></i><br />hand-o-left
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hand-o-right fa-lg mt-4"></i><br />hand-o-right
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hand-o-up fa-lg mt-4"></i><br />hand-o-up
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-long-arrow-down fa-lg mt-4"></i><br />long-arrow-down
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-long-arrow-left fa-lg mt-4"></i><br />long-arrow-left
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-long-arrow-right fa-lg mt-4"></i><br />long-arrow-right
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-long-arrow-up fa-lg mt-4"></i><br />long-arrow-up
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-toggle-down fa-lg mt-4"></i><br />toggle-down <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-toggle-left fa-lg mt-4"></i><br />toggle-left <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-toggle-right fa-lg mt-4"></i><br />toggle-right <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-toggle-up fa-lg mt-4"></i><br />toggle-up <span className="text-muted">(alias)</span>
              </Col>

            </Row>
          </CardBody>
        </Card>

        <Card id="video-player">
          <CardHeader>Video Player Icons</CardHeader>
          <CardBody>
            <Row className="text-center">

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-arrows-alt fa-lg mt-4"></i><br />arrows-alt
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-backward fa-lg mt-4"></i><br />backward
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-compress fa-lg mt-4"></i><br />compress
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-eject fa-lg mt-4"></i><br />eject
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-expand fa-lg mt-4"></i><br />expand
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-fast-backward fa-lg mt-4"></i><br />fast-backward
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-fast-forward fa-lg mt-4"></i><br />fast-forward
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-forward fa-lg mt-4"></i><br />forward
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-pause fa-lg mt-4"></i><br />pause
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-pause-circle fa-lg mt-4"></i><br />pause-circle
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-pause-circle-o fa-lg mt-4"></i><br />pause-circle-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-play fa-lg mt-4"></i><br />play
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-play-circle fa-lg mt-4"></i><br />play-circle
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-play-circle-o fa-lg mt-4"></i><br />play-circle-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-random fa-lg mt-4"></i><br />random
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-step-backward fa-lg mt-4"></i><br />step-backward
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-step-forward fa-lg mt-4"></i><br />step-forward
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-stop fa-lg mt-4"></i><br />stop
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-stop-circle fa-lg mt-4"></i><br />stop-circle
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-stop-circle-o fa-lg mt-4"></i><br />stop-circle-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-youtube-play fa-lg mt-4"></i><br />youtube-play
              </Col>

            </Row>
          </CardBody>
        </Card>

        <Card id="brand">
          <CardHeader>Brand Icons</CardHeader>
          <CardBody>
            <Alert color="warning">
              <h4><i className="fa fa-warning"></i> Warning!</h4>
              Apparently, Adblock Plus can remove Font Awesome brand icons with their "Remove Social
              Media Buttons" setting. We will not use hacks to force them to display. Please
              <a href="https://adblockplus.org/en/bugs" className="alert-link"> report an issue with Adblock Plus</a> if
              you believe this to be
              an error. To work around this, you'll need to modify the social icon class names.
            </Alert>
            <Row className="text-center">

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-500px fa-lg mt-4"></i><br />500px
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-adn fa-lg mt-4"></i><br />adn
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-amazon fa-lg mt-4"></i><br />amazon
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-android fa-lg mt-4"></i><br />android
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-angellist fa-lg mt-4"></i><br />angellist
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-apple fa-lg mt-4"></i><br />apple
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-behance fa-lg mt-4"></i><br />behance
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-behance-square fa-lg mt-4"></i><br />behance-square
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bitbucket fa-lg mt-4"></i><br />bitbucket
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bitbucket-square fa-lg mt-4"></i><br />bitbucket-square
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bitcoin fa-lg mt-4"></i><br />bitcoin <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-black-tie fa-lg mt-4"></i><br />black-tie
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bluetooth fa-lg mt-4"></i><br />bluetooth
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-bluetooth-b fa-lg mt-4"></i><br />bluetooth-b
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-btc fa-lg mt-4"></i><br />btc
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-buysellads fa-lg mt-4"></i><br />buysellads
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-cc-amex fa-lg mt-4"></i><br />cc-amex
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-cc-diners-club fa-lg mt-4"></i><br />cc-diners-club
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-cc-discover fa-lg mt-4"></i><br />cc-discover
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-cc-jcb fa-lg mt-4"></i><br />cc-jcb
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-cc-mastercard fa-lg mt-4"></i><br />cc-mastercard
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-cc-paypal fa-lg mt-4"></i><br />cc-paypal
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-cc-stripe fa-lg mt-4"></i><br />cc-stripe
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-cc-visa fa-lg mt-4"></i><br />cc-visa
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-chrome fa-lg mt-4"></i><br />chrome
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-codepen fa-lg mt-4"></i><br />codepen
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-codiepie fa-lg mt-4"></i><br />codiepie
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-connectdevelop fa-lg mt-4"></i><br />connectdevelop
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-contao fa-lg mt-4"></i><br />contao
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-css3 fa-lg mt-4"></i><br />css3
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-dashcube fa-lg mt-4"></i><br />dashcube
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-delicious fa-lg mt-4"></i><br />delicious
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-deviantart fa-lg mt-4"></i><br />deviantart
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-digg fa-lg mt-4"></i><br />digg
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-dribbble fa-lg mt-4"></i><br />dribbble
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-dropbox fa-lg mt-4"></i><br />dropbox
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-drupal fa-lg mt-4"></i><br />drupal
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-edge fa-lg mt-4"></i><br />edge
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-empire fa-lg mt-4"></i><br />empire
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-expeditedssl fa-lg mt-4"></i><br />expeditedssl
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-facebook fa-lg mt-4"></i><br />facebook
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-facebook-f fa-lg mt-4"></i><br />facebook-f <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-facebook-official fa-lg mt-4"></i><br />facebook-official
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-facebook-square fa-lg mt-4"></i><br />facebook-square
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-firefox fa-lg mt-4"></i><br />firefox
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-flickr fa-lg mt-4"></i><br />flickr
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-fonticons fa-lg mt-4"></i><br />fonticons
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-fort-awesome fa-lg mt-4"></i><br />fort-awesome
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-forumbee fa-lg mt-4"></i><br />forumbee
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-foursquare fa-lg mt-4"></i><br />foursquare
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-ge fa-lg mt-4"></i><br />ge <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-get-pocket fa-lg mt-4"></i><br />get-pocket
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-gg fa-lg mt-4"></i><br />gg
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-gg-circle fa-lg mt-4"></i><br />gg-circle
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-git fa-lg mt-4"></i><br />git
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-git-square fa-lg mt-4"></i><br />git-square
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-github fa-lg mt-4"></i><br />github
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-github-alt fa-lg mt-4"></i><br />github-alt
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-github-square fa-lg mt-4"></i><br />github-square
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-gittip fa-lg mt-4"></i><br />gittip <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-google fa-lg mt-4"></i><br />google
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-google-plus fa-lg mt-4"></i><br />google-plus
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-google-plus-square fa-lg mt-4"></i><br />google-plus-square
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-google-wallet fa-lg mt-4"></i><br />google-wallet
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-gratipay fa-lg mt-4"></i><br />gratipay
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hacker-news fa-lg mt-4"></i><br />hacker-news
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-houzz fa-lg mt-4"></i><br />houzz
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-html5 fa-lg mt-4"></i><br />html5
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-instagram fa-lg mt-4"></i><br />instagram
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-internet-explorer fa-lg mt-4"></i><br />internet-explorer
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-ioxhost fa-lg mt-4"></i><br />ioxhost
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-joomla fa-lg mt-4"></i><br />joomla
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-jsfiddle fa-lg mt-4"></i><br />jsfiddle
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-lastfm fa-lg mt-4"></i><br />lastfm
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-lastfm-square fa-lg mt-4"></i><br />lastfm-square
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-leanpub fa-lg mt-4"></i><br />leanpub
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-linkedin fa-lg mt-4"></i><br />linkedin
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-linkedin-square fa-lg mt-4"></i><br />linkedin-square
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-linux fa-lg mt-4"></i><br />linux
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-maxcdn fa-lg mt-4"></i><br />maxcdn
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-meanpath fa-lg mt-4"></i><br />meanpath
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-medium fa-lg mt-4"></i><br />medium
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-mixcloud fa-lg mt-4"></i><br />mixcloud
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-modx fa-lg mt-4"></i><br />modx
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-odnoklassniki fa-lg mt-4"></i><br />odnoklassniki
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-odnoklassniki-square fa-lg mt-4"></i><br />odnoklassniki-square
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-opencart fa-lg mt-4"></i><br />opencart
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-openid fa-lg mt-4"></i><br />openid
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-opera fa-lg mt-4"></i><br />opera
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-optin-monster fa-lg mt-4"></i><br />optin-monster
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-pagelines fa-lg mt-4"></i><br />pagelines
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-paypal fa-lg mt-4"></i><br />paypal
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-pied-piper fa-lg mt-4"></i><br />pied-piper
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-pied-piper-alt fa-lg mt-4"></i><br />pied-piper-alt
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-pinterest fa-lg mt-4"></i><br />pinterest
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-pinterest-p fa-lg mt-4"></i><br />pinterest-p
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-pinterest-square fa-lg mt-4"></i><br />pinterest-square
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-product-hunt fa-lg mt-4"></i><br />product-hunt
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-qq fa-lg mt-4"></i><br />qq
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-ra fa-lg mt-4"></i><br />ra <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-rebel fa-lg mt-4"></i><br />rebel
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-reddit fa-lg mt-4"></i><br />reddit
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-reddit-alien fa-lg mt-4"></i><br />reddit-alien
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-reddit-square fa-lg mt-4"></i><br />reddit-square
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-renren fa-lg mt-4"></i><br />renren
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-safari fa-lg mt-4"></i><br />safari
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-scribd fa-lg mt-4"></i><br />scribd
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-sellsy fa-lg mt-4"></i><br />sellsy
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-share-alt fa-lg mt-4"></i><br />share-alt
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-share-alt-square fa-lg mt-4"></i><br />share-alt-square
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-shirtsinbulk fa-lg mt-4"></i><br />shirtsinbulk
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-simplybuilt fa-lg mt-4"></i><br />simplybuilt
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-skyatlas fa-lg mt-4"></i><br />skyatlas
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-skype fa-lg mt-4"></i><br />skype
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-slack fa-lg mt-4"></i><br />slack
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-slideshare fa-lg mt-4"></i><br />slideshare
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-soundcloud fa-lg mt-4"></i><br />soundcloud
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-spotify fa-lg mt-4"></i><br />spotify
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-stack-exchange fa-lg mt-4"></i><br />stack-exchange
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-stack-overflow fa-lg mt-4"></i><br />stack-overflow
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-steam fa-lg mt-4"></i><br />steam
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-steam-square fa-lg mt-4"></i><br />steam-square
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-stumbleupon fa-lg mt-4"></i><br />stumbleupon
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-stumbleupon-circle fa-lg mt-4"></i><br />stumbleupon-circle
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-tencent-weibo fa-lg mt-4"></i><br />tencent-weibo
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-trello fa-lg mt-4"></i><br />trello
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-tripadvisor fa-lg mt-4"></i><br />tripadvisor
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-tumblr fa-lg mt-4"></i><br />tumblr
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-tumblr-square fa-lg mt-4"></i><br />tumblr-square
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-twitch fa-lg mt-4"></i><br />twitch
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-twitter fa-lg mt-4"></i><br />twitter
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-twitter-square fa-lg mt-4"></i><br />twitter-square
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-usb fa-lg mt-4"></i><br />usb
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-viacoin fa-lg mt-4"></i><br />viacoin
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-vimeo fa-lg mt-4"></i><br />vimeo
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-vimeo-square fa-lg mt-4"></i><br />vimeo-square
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-vine fa-lg mt-4"></i><br />vine
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-vk fa-lg mt-4"></i><br />vk
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-wechat fa-lg mt-4"></i><br />wechat <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-weibo fa-lg mt-4"></i><br />weibo
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-weixin fa-lg mt-4"></i><br />weixin
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-whatsapp fa-lg mt-4"></i><br />whatsapp
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-wikipedia-w fa-lg mt-4"></i><br />wikipedia-w
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-windows fa-lg mt-4"></i><br />windows
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-wordpress fa-lg mt-4"></i><br />wordpress
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-xing fa-lg mt-4"></i><br />xing
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-xing-square fa-lg mt-4"></i><br />xing-square
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-y-combinator fa-lg mt-4"></i><br />y-combinator
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-y-combinator-square fa-lg mt-4"></i><br />y-combinator-square <span
                className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-yahoo fa-lg mt-4"></i><br />yahoo
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-yc fa-lg mt-4"></i><br />yc <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-yc-square fa-lg mt-4"></i><br />yc-square <span className="text-muted">(alias)</span>
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-yelp fa-lg mt-4"></i><br />yelp
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-youtube fa-lg mt-4"></i><br />youtube
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-youtube-play fa-lg mt-4"></i><br />youtube-play
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-youtube-square fa-lg mt-4"></i><br />youtube-square
              </Col>
            </Row>
            <Alert color="success" className="mt-4">
              <ul className="margin-bottom-none padding-left-lg">
                <li>All brand icons are trademarks of their respective owners.</li>
                <li>The use of these trademarks does not indicate endorsement of the trademark holder by Font Awesome,
                  nor vice versa.
                </li>
                <li>Brand icons should only be used to represent the company or product to which they refer.</li>
              </ul>
            </Alert>
          </CardBody>
        </Card>

        <Card id="medical">
          <CardHeader>Medical Icons</CardHeader>
          <CardBody>
            <Row className="text-center">

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-ambulance fa-lg mt-4"></i><br />ambulance
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-h-square fa-lg mt-4"></i><br />h-square
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-heart fa-lg mt-4"></i><br />heart
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-heart-o fa-lg mt-4"></i><br />heart-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-heartbeat fa-lg mt-4"></i><br />heartbeat
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-hospital-o fa-lg mt-4"></i><br />hospital-o
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-medkit fa-lg mt-4"></i><br />medkit
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-plus-square fa-lg mt-4"></i><br />plus-square
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-stethoscope fa-lg mt-4"></i><br />stethoscope
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-user-md fa-lg mt-4"></i><br />user-md
              </Col>

              <Col xs="6" sm="4" md="3" lg="2">
                <i className="fa fa-wheelchair fa-lg mt-4"></i><br />wheelchair
              </Col>

            </Row>
          </CardBody>
        </Card>
      </div>

    );
  }
}

export default FontAwesome;
