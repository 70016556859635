import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import reducer from './reducers';
import { CONSOLE_LOG } from '../config.js';

var store =  createStore(reducer, applyMiddleware(thunk, logger));
if( !CONSOLE_LOG ){
    store =  createStore(reducer, applyMiddleware(thunk));
}

export default store;