import React, { Component, Fragment } from 'react';
import { Button, Card, Col, CardFooter, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Label } from 'reactstrap';
import { oneOf, RIFmask } from '../../handlers/Utils';
import { connect } from 'react-redux';
import { Notification, Preloader} from '../Plugins/';
import { register, setValues, verifyDomain, resendEmailConfirm, socialLogin, registerFromSocial } from '../../store/actions/userActions';
import { clear, has, get, vanish, closeNotification } from '../../store/actions/notificationsActions';
import { Link } from 'react-router-dom';
import { addNotification, clearNotification } from '../../store/actions/notificationsActions';
import logo_login from '../../assets/img/brand/logo_login.png';
import email from  '../../assets/img/email.svg';
import venezuela from '../../assets/data/venezuela.json';

import { FB_APP_ID, GOOGLE_APP_ID } from '../../config';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';

let estados = [];
for(let idx in venezuela){
	estados.push(venezuela[idx])
}


class Register extends Component {

	constructor (props){
		super(props);

		this.state = {
			client_url: window.location.origin + "/confirmation",
			views_data: '',
			data: {
				city: '',
				commerce_name: '',
				commerce_rif: '',
				email: '',
				lastname: '',
				name: ''
			},
			cities: [],
			state: '',
			errorEmail:'',
			errorPassword:'',
			errorRif:'',
		};
	}

	componentDidUpdate (prevProps, prevState){
		if ( this.props.notification.level === 2) { /* 2 = success */
		this.setState({
			...prevState,
			views_data: 'hasConfirmedEmail'
		});
		this.props.closeNotification();
		}
	}

	handleSocialLogin = async (user, provider) => {

		user.provider = provider;
		if( provider === "facebook" ){
		await this.props.socialLogin(user);

		} else if(provider === "google"){

		user.email = user.profileObj.email;
		await this.props.socialLogin(user);
		}
	}

	handleSocialLoginFailure = (err, a, b ,c ,d ,e) => {
		console.error(err)
	}

	replaceWithUppercase = (value) => {
		const newValue = value.toUpperCase();

		this.setState({
			data: {
				...this.state.data,
				commerce_rif: newValue
			}
		});

		return newValue;
	}

	citiesByState = user_state_id => {
		const estado = estados.find(
			state => 
				{ return String(state.id_estado) === user_state_id}
		);

		this.setState({
			cities: estado.ciudades,
			state: estado.id_estado
		})
	}

	validacionForm = () => {
		let numError = 0;
		let data = this.state.data;
		let notification = {
			message: 'la Información no es correcta para el registro.',
			title: '',
			level: 4,
			errors: {}
		};

		if (data.name === ''){
			numError++;
			notification.errors.name = '<i className="icon-close icons"></i>';
		} 
		if (data.city === ''){
			numError++;
			notification.errors.city = '<i className="icon-close icons"></i>';
		} 
		if (data.commerce_name === ''){
			numError++;
			notification.errors.commerce_name = '<i className="icon-close icons"></i>';
		} 

		if (data.commerce_rif === ''){
			numError++;
			notification.errors.commerce_rif = '<i className="icon-close icons"></i>';
		} else if(!/[VEJPG]-[0-9]{1,8}/.test( data.commerce_rif )) {
			numError++;
			notification.errors.commerce_rif = '<i className="icon-close icons"></i>';
		}

		if (data.email === ''){
			numError++;
			notification.errors.email = '<i className="icon-close icons"></i>';
		}else if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,7})+$/.test( data.email )){
			numError++;
			notification.errors.email = '<i className="icon-close icons"></i>';
		} 

		if (data.lastname === ''){
			numError++;
			notification.errors.lastname = '<i className="icon-close icons"></i>';
		}

		if( numError === 0){
			
			return true;
		}else {
			this.props.addNotification( notification );
			return false
		}
		
	}

	saveData = () => {
		let data = this.state.data;
		//data.commerce_rif = data.commerce_rif.indexOf("_") !== -1 ? '' : data.commerce_rif;
		if( this.validacionForm() ){
			if (this.props.valid_registration)
				this.props.registerFromSocial({...data, ...this.props.social_data}, this.state.client_url);
			else
				this.props.register(data, this.state.client_url);
		}
		
	}

  	onChange = (target) => {
	  let { name, value } = target;
	  if (name !== 'domain' && name !== 'commerce_rif') {
		  if(!oneOf(["url", "email", "password"], target.type)) {
			  if (target.name !== "company_name") {
				  value = target.value.charAt(0).toUpperCase() + target.value.slice(1)
			  }
		  }
	  }

	  this.setState(prevState => ({
		  ...prevState,
		  data: {
			  ...prevState.data,
			  [name]: value
		  }
	  }));
	  };
	  
	onChange = (target, validation = null) => {
		let { name, value } = target;
		let val = false;
		
		if (name !== 'domain' && name !== 'rif'){
			if(!oneOf(["url", "email", "password"], target.type)) {
				if (target.name !== "company_name") {
					value = target.value.charAt(0).toUpperCase() + target.value.slice(1)
				}
			}
		}
    
		if(validation != null){
			if(value !== ""){
				if( validation.test( value ) ){
					val = true;
				}
			} else {
				val = true;
			}
		} else {
			val = true;
		}

		if(val){
			if(name === 'email'){
                let classError = '';
                if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,7})+$/.test( value )){
                    classError = 'input-error';
                } else {
                    classError = '';
                }
                this.setState(prevState => ({
                    ...prevState,
                    errorEmail: classError
                }));
			}

			if(name === 'password'){
				let classError = '';
                if( value.length < 6 ){
                    classError = 'input-error';
                } else {
                    classError = '';
                }
                this.setState(prevState => ({
                    ...prevState,
                    errorPassword: classError
                }));
			}

			if(name === 'commerce_rif'){
				let classError = '';
                if( !(/[VEJPG]-[0-9]{1,8}/.test( value )) ){
                    classError = 'input-error';
                } else {
                    classError = '';
                }
                this.setState(prevState => ({
                    ...prevState,
                    errorRif: classError
                }));
			}
			
			this.setState(prevState => ({
				...prevState,
				data: {
					...prevState.data,
					[name]: value
				}
			}));
		}
	};


  render() {
	let views_data = (
	  <Row className="justify-content-center register-only">
			<Col md="6">
				<Card className="p-2 reguister_card">
					<Form>
						{
							this.props.valid_registration ?
							(
								<Fragment> 
									<h1  className="text-center">Completa el registro</h1>
									<p className="text-muted mb-3">Creando nuevo usuario:</p>

									<Label><b>Nombre Administrador:</b> {this.props.social_data.name}</Label> <br/>
									<Label><b>Apellido Administrador:</b> {this.props.social_data.lastname}</Label> <br/>
									<Label><b>Email Administrador:</b> {this.props.social_data.email}</Label> <br/>

									<hr/>
								</Fragment>
							)
							: (
								<Fragment>
									<h1  className="text-center">Registro</h1>
									<p className="text-muted mb-3">Crea tu cuenta</p>

									<Label>Nombre Administrador <span className="text-danger">*</span></Label>
									<InputGroup className="mb-3">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
											<i className="icon-user"></i>
											</InputGroupText>
										</InputGroupAddon>
										<Input type="text" autoComplete="name" name="name" onChange={(e) => this.onChange(e.target, /^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ\s]+$/ )} value={ this.state.data.name || "" }/>
										{ this.props.has('name') ? 
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
												<i className="icon-close icons text-danger"></i>
												</InputGroupText>
											</InputGroupAddon> 
											: (null) }
									</InputGroup>

									<Label>Apellido Administrador <span className="text-danger">*</span></Label>
									<InputGroup className="mb-3">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
											<i className="icon-user"></i>
											</InputGroupText>
										</InputGroupAddon>
										<Input type="text" autoComplete="lastname" name="lastname" onChange={(e) => this.onChange(e.target, /^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ\s]+$/ )} value={ this.state.data.lastname || "" }/>
										{ this.props.has('lastname') ?
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
												<i className="icon-close icons text-danger"></i>
												</InputGroupText>
											</InputGroupAddon> 
											: (null) }
									</InputGroup>

									<Label>Email Administrador <span className="text-danger">*</span></Label>
									<InputGroup className="mb-3">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>@</InputGroupText>
										</InputGroupAddon>
										<Input type="email" className={this.state.errorEmail} autoComplete="email" name="email" onChange={ (e) => this.onChange(e.target) } value={ this.state.data.email || "" }/>
										{ this.props.has('email') ?
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
												<i className="icon-close icons text-danger"></i>
												</InputGroupText>
											</InputGroupAddon> 
											: (null) }
									</InputGroup>
								</Fragment>
							) 
						}
						{
							this.props.valid_registration && (
								<Fragment><Label>Contraseña de usuario <span className="text-danger">*</span></Label>
								<InputGroup className="mb-3">
								<InputGroupAddon addonType="prepend">
									<InputGroupText>
									<i className="fa fa-lock"></i>
									</InputGroupText>
								</InputGroupAddon>
								<Input type="password"
									maxlength="16"
									className={this.state.errorPassword}
									autoComplete="password" name="password" onChange={ (e) => this.onChange(e.target) } value={ this.state.data.password || "" }/>
								{ this.props.has('password') ? 
								<InputGroupAddon addonType="prepend">
									<InputGroupText>
									<i className="icon-close icons text-danger"></i>
									</InputGroupText>
								</InputGroupAddon> 
								: (null) }
								</InputGroup>
								</Fragment>
							)
						}

						<Label>Nombre Comercio <span className="text-danger">*</span></Label>
						<InputGroup className="mb-3">
							<InputGroupAddon addonType="prepend">
								<InputGroupText>
								<i className="fa fa-industry"></i>
								</InputGroupText>
							</InputGroupAddon>
							<Input type="text" autoComplete="commerce_name" name="commerce_name" onChange={(e) => this.onChange(e.target, /^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ\s]+$/ )} value={ this.state.data.commerce_name || "" }/>
							{ this.props.has('commerce_name') ?
							<InputGroupAddon addonType="prepend">
								<InputGroupText>
								<i className="icon-close icons text-danger"></i>
								</InputGroupText>
							</InputGroupAddon> 
							: (null) }
						</InputGroup>

						<Label>RIF Comercio <span className="text-danger">*</span></Label>
						<InputGroup className="mb-3">
							<InputGroupAddon addonType="prepend">
								<InputGroupText>
								<i className="icon-user"></i>
								</InputGroupText>
							</InputGroupAddon>
							<Input type="text" className={this.state.errorRif} autoComplete="commerce_rif" name="commerce_rif" onChange={(e) => this.onChange(e.target )} value={ this.state.data.commerce_rif || "" }/>
							{
								this.props.has('commerce_rif') ?
								<InputGroupAddon addonType="prepend">
									<InputGroupText>
									<i className="icon-close icons text-danger"></i>
									</InputGroupText>
								</InputGroupAddon> 
								: (null)
							}
						</InputGroup>

						<Label>Estado donde se encuentra el comercio <span className="text-danger">*</span></Label>
						<InputGroup className="mb-3">
							<InputGroupAddon addonType="prepend">
								<InputGroupText>
								<i className="icon-globe"></i>
								</InputGroupText>
							</InputGroupAddon>
								<Input type="select"
								className="register-select"
								autoComplete="state" name="state" onChange={ (e) => this.citiesByState(e.target.value) } value={ this.state.state || "" } >
								<option value="">Seleccione...</option>
								{
										estados.map( (state, key) => <option key={key} value={state.id_estado}>{state.estado}</option> )
								}
								</Input>
							{ this.props.has('state') ? 
							<InputGroupAddon addonType="prepend">
								<InputGroupText>
								<i className="icon-close icons text-danger"></i>
								</InputGroupText>
							</InputGroupAddon> 
							: (null) }
						</InputGroup>

						<Label>Ciudad donde se encuentra el comercio <span className="text-danger">*</span></Label>
						<InputGroup className="mb-3">
							<InputGroupAddon addonType="prepend">
								<InputGroupText>
								<i className="icon-globe"></i>
								</InputGroupText>
							</InputGroupAddon>
								<Input type="select"
								className="register-select"
								autoComplete="city" name="city" onChange={ (e) => this.onChange(e.target) } value={ this.state.data.city || "" } >
								<option value="">Seleccione...</option>
								{
									this.state.cities.map( (city, key) => <option key={key} value={city}>{city}</option> )
								}
								</Input>
							{ this.props.has('city') ? 
							<InputGroupAddon addonType="prepend">
								<InputGroupText>
								<i className="icon-close icons text-danger"></i>
								</InputGroupText>
							</InputGroupAddon> 
							: (null) }
						</InputGroup>
						<Button color="primary" className="logo_login" onClick={ () => this.saveData() }>Crear una cuenta</Button>
						</Form>
						{
							!this.props.valid_registration && (
								<Row>
									<Col xs="12" className="text-center">
										<b className="text_fran"> ¿Ya tienes una cuenta? </b>
									</Col>

									<Col xs="12" className="text-center">
										<Link to = "/login" style={{textDecoration: 'none'}}>
											<Button color="primary" className="logo_login">Inicia sesión</Button>
										</Link>
									</Col>
									<Col xs="6" className="text-center p-20">
										<FacebookLogin
											textButton = ""
											appId = {FB_APP_ID}
											autoLoad={false}
											fields="name,email"
											size="small"
											callback={e => this.handleSocialLogin(e, "facebook")}
											icon="fa fa-facebook"
										/>
									</Col>
									<Col xs="6" className="text-center p-20">
										<GoogleLogin
											className="my-2"
											clientId = {GOOGLE_APP_ID}
											buttonText=""
											onSuccess={e => this.handleSocialLogin(e, "google")}
											onFailure={e => this.handleSocialLoginFailure(e)} 
										/>
									</Col>
								</Row>
							)
						}
						
					</Card>
				</Col>
		</Row>
	);


	switch ( this.state.views_data ) {
	  case 'hasConfirmedEmail':
		  views_data = (
			<Row className="justify-content-center">
			  <Col md="8" className="text-center">
				  <div className="text-center mb-2"><img src={logo_login} width="120" alt="logo"/></div>
				  <h2 className="text_app">Por favor revisa tu bandeja de correo para verificar tu cuenta.</h2>
				  <img  className="logo_login"  width="200"  src={ email } alt="email_sent_image"/>
				  <Button color="link" onClick={ () => this.props.resendEmailConfirm(this.state.data) }>No he recibido el correo electrónico</Button>
				  <CardFooter className="eader-register text-center">
					  <p className = "text_app" >Si tienes una cuenta, puedes iniciar sesión en Kryptopago. </p>

					  <Link to="/login"><Button color="link">Iniciar sesión</Button></Link>
				  </CardFooter>
			  </Col>
			</Row>
		  )
		  break;

	  default:

	  break;
	}

	return (
	  <div className="app flex-row align-items-center d-block">
		<Container>
		  { views_data }
		  {
			/* los mensajes se muestran como pantallas no como notificacion */
			  this.props.notification.level === 2 ? (null) : <Notification />
		  }
		  <Preloader />
		</Container>
	  </div>
	);
  }
}


const mapStateToProps = (state) => {
	return {
		user: state.clientReducer.user,
		notification: state.notificationReducer,
		valid_registration: state.clientReducer.valid_registration,
		social_data: state.clientReducer.social_data
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		setValues: (target) => dispatch(setValues(target)),
		register: (data, client_url) => dispatch(register(data, client_url)),
		registerFromSocial: (data, client_url) => dispatch(registerFromSocial(data, client_url)),
		socialLogin: user => dispatch(socialLogin(user)),
		resendEmailConfirm: (data) => dispatch(resendEmailConfirm(data)),
		clear: (field, prefix) => dispatch(clear(field, prefix)),
		has: (field) => dispatch(has(field)),
		get: (field) => dispatch(get(field)),
		verifyDomain: ( domain ) => dispatch(verifyDomain( domain )),
		vanish: () => dispatch(vanish()),
		addNotification: (field) => dispatch(addNotification(field)),
		closeNotification: () => dispatch(closeNotification())
	}
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
