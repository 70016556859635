
const initialState = {
    commerce: sessionStorage.getItem('commerce') ? JSON.parse(sessionStorage.getItem('commerce')) : {},
    commerces: {
        data:[]
    },
    dashboard: {
        tasa_del_dia:'',
        numero_operaciones: 0,
        volumen_bs: '',
        valor: '',
        saldos:[]
    }
};

const commerceReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_COMMERCES':
            return {...state, commerces: action.commerces };

        case 'FETCH_COMMERCE':
            return { ...state, commerce: action.commerce };

        case 'UPDATE_COMMERCE':
            return { ...state, commerce: action.commerce };

        case 'BANK_ASSIGNATION':
            return { ...state, commerces: action.commerce };

        case 'FETCH_DASHBOARD':
            return { ...state, dashboard: action.dashboard };
        default:
            return state;

    }
};

export default commerceReducer;
