import { URL } from '../../config.js';
import NotificationHandler from '../../handlers/Notifications';
import axios from 'axios';
import store from '../store';
import { logout } from './userActions';

/**
 * @description logs in the user
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any> | never | never>}
 */

const fetchBanks = () => {
    return (dispatch) => {
        dispatch({type: "WAIT_FOR_RESPONSE"});
        return axios.get(URL + '/bank/index').then( (res) => {

            let user = store.getState().clientReducer.session;
            if(user.type === 2) {
                let aux = [];
                let val = 0;
                res.data.banks.forEach(element => {
                    if(element.commerces.length > 0){
                        aux.push(element.id);
                        val = val + 1;
                    }
                });

                dispatch({
                    type: 'SELECTE_BANK',
                    selecteBanks: aux
                });

                dispatch({
                    type: 'SELECTE_BANK',
                    selecteBanks: aux
                });

                if(val === 0){
                    dispatch({
                        type: 'ALERT_BANK',
                        alertBanc: true
                    });
                }else {
                    dispatch({
                        type: 'ALERT_BANK',
                        alertBanc: false
                    }); 
                }

            }

            dispatch({
                type: 'FETCH_BANKS',
                banks: res.data.banks
            });

            dispatch({type: "WAIT_FOR_RESPONSE"});
        }).catch( (err) => {
            if( (err.response.status === 401) && (err.response.data.error.message === 'Token has expired')){
                dispatch( logout() );
            }
            dispatch({
                type: 'NOTIFICATION',
                notification: NotificationHandler(err.response),
            });
        });
    }
};

const fetchBank = (id) => {
    return (dispatch) => {
        dispatch({type: "WAIT_FOR_RESPONSE"});
        return axios.get(URL + '/bank/show').then( (res) => {

            dispatch({
                type: 'FETCH_BANK',
                bank: res.data
            });
            dispatch({type: "WAIT_FOR_RESPONSE"});
        }).catch( (err) => {
            if( (err.response.status === 401) && (err.response.data.error.message === 'Token has expired')){
                dispatch( logout() );
            }
            dispatch({
                type: 'NOTIFICATION',
                notification: NotificationHandler(err.response),
            });
        });
    }
};


const changeBankStatus = (id, status) => {
    return(dispatch) => {

    	return axios.patch(`${URL}/bank/active/${id}/${status ? '1' : '0'}`).then((res) => {

    		dispatch(fetchBanks());

    	}).catch((err) => {
            if( (err.response.status === 401) && (err.response.data.error.message === 'Token has expired')){
                dispatch( logout() );
            }
    		dispatch({
    			type: 'NOTIFICATION',
    			notification: NotificationHandler(err.response)
    		})

    	});
    }
}


const storeBank = (data) => {
    return (dispatch) => {
        dispatch({type: "WAIT_FOR_RESPONSE"});
		if (data.hasOwnProperty('id')) {

			axios.put(URL + '/bank/update', data).then((res) => {

				dispatch({
					type: "WAIT_FOR_RESPONSE"
				});

				dispatch({
					type: 'NOTIFICATION',
					notification: NotificationHandler(res)
				});

				dispatch(fetchBanks());
			}).catch((err) => {
                if( (err.response.status === 401) && (err.response.data.error.message === 'Token has expired')){
                    dispatch( logout() );
                }
				dispatch({
					type: "WAIT_FOR_RESPONSE"
				});
				dispatch({
					type: 'NOTIFICATION',
					notification: NotificationHandler(err.response)
				})
			});

		} else {

			axios.post(URL + '/bank/store', data).then((res) => {

				dispatch({
					type: "WAIT_FOR_RESPONSE"
				});
				dispatch(fetchBanks());
				dispatch({
					type: 'NOTIFICATION',
					notification: NotificationHandler(res)
				});
			}).catch((err) => {
                if( (err.response.status === 401) && (err.response.data.error.message === 'Token has expired')){
                    dispatch( logout() );
                }
				dispatch({
					type: "WAIT_FOR_RESPONSE"
				});
				dispatch({
					type: 'NOTIFICATION',
					notification: NotificationHandler(err.response)
				})
			});
		}
    }
};

const updateBankSelecte = (data, cheket) => {
    return (dispatch) => {
        let selecte_Banks = store.getState().bankReducer.selecteBanks;
        if(selecte_Banks.length === 0){
            selecte_Banks.push(data)
        }else{
            var aux = selecte_Banks.indexOf(data);
            if (aux !== -1){
                selecte_Banks.splice(aux, 1);
            }else{
                selecte_Banks.push(data);
            }
        }

        dispatch({
            type: 'SELECTE_BANK',
            selecteBanks: selecte_Banks
        });
    }
};

const deleteBank = (id) => {
	return (dispatch) => {
		dispatch({
			type: "WAIT_FOR_RESPONSE"
		});
		/* esto debe ser: + id_bank no como payload */
		axios.delete(URL + '/bank/destroy/' + id).then((res) => {

			dispatch({
				type: "WAIT_FOR_RESPONSE"
			});
			dispatch({
				type: 'NOTIFICATION',
				notification: NotificationHandler(res)
			});
			dispatch(fetchBanks());
		}).catch((err) => {
            if( (err.response.status === 401) && (err.response.data.error.message === 'Token has expired')){
                dispatch( logout() );
            }
			dispatch({
				type: "WAIT_FOR_RESPONSE"
			});
			dispatch({
				type: 'NOTIFICATION',
				notification: NotificationHandler(err.response)
			})
		});
	}
};


export {
    fetchBanks,
    storeBank,
    fetchBank,
    deleteBank,
    changeBankStatus,
    updateBankSelecte
};
