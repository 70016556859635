import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Label } from 'reactstrap';
import logo_login from '../../assets/img/brand/logo_login.png';
import { Link } from 'react-router-dom';
import { clear, has, get, addNotification, closeNotification } from '../../store/actions/notificationsActions';
import { login, socialLogin, registerFromSocial, clearStateUser } from '../../store/actions/userActions';
import { Notification, Preloader} from '../Plugins/';
import { oneOf } from '../../handlers/Utils';
import { FB_APP_ID, GOOGLE_APP_ID } from '../../config';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';

import venezuela from '../../assets/data/venezuela.json';

let estados = [];
for (let idx in venezuela) {
	estados.push(venezuela[idx])
}

let data = {
	'email': '',
	'password': ''
};

class Login extends Component {

  constructor (props){
    super(props);
    if (sessionStorage.getItem('user') && (sessionStorage.getItem('user') !== null)) {
        this.props.history.push('/');
    }

    this.state = {
      client_url: window.location.origin + "/confirmation",
      views_data: '',
      errorEmail : '',
      errorPassword : '',
      errorRif : '',
      activeBtnLogin : false,
      data: {
        city: '',
				commerce_name: '',
				commerce_rif: '',
				email: '',
				lastname: '',
				name: ''
      },
      cities: [],
      state: ''
    };
  }


  componentDidMount(){
    if(!this.props.valid_registration) {
      if( this.validacionLogin(true) ){
        this.activeBtn();
      }else {
        this.noActiveBtn();
      }
    }
  }
  componentDidUpdate(prevProps) {
		if(this.props.session !== undefined){
			if(this.props.session.is_active) {
				this.props.history.push('/')
			}
		}
	}

  handleSocialLogin = async (user, provider) => {

    user.provider = provider;
    if( provider === "facebook" ){
      await this.props.socialLogin(user);

    } else if(provider === "google"){

      user.email = user.profileObj.email;
      await this.props.socialLogin(user);
    }
  }

  handleSocialLoginFailure = (err, a, b ,c ,d ,e) => {
  	console.error(err)
  }

	replaceWithUppercase = (value) => {
		const newValue = value.toUpperCase();

		this.setState({
			data: {
				...this.state.data,
				rif: newValue
			}
		});

		return newValue;
	}

	citiesByState = user_state_id => {
		const estado = estados.find(
			state => 
				{ return String(state.id_estado) === user_state_id}
		);

		this.setState({
			cities: estado.ciudades,
			state: estado.id_estado
		})
  }
  
  validacionForm = () => {
		let numError = 0;
		let data = this.state.data;
		let notification = {
			message: 'la Información no es correcta para el registro.',
			title: '',
			level: 4,
			errors: {}
		};

		if (data.name === ''){
			numError++;
			notification.errors.name = '<i className="icon-close icons"></i>';
		} 
		if (data.city === ''){
			numError++;
			notification.errors.city = '<i className="icon-close icons"></i>';
		} 
		if (data.commerce_name === ''){
			numError++;
			notification.errors.commerce_name = '<i className="icon-close icons"></i>';
		} 

		if (data.commerce_rif === ''){
			numError++;
			notification.errors.commerce_rif = '<i className="icon-close icons"></i>';
		} else if(!/[VEJPG]-[0-9]{1,8}/.test( data.commerce_rif )) {
			numError++;
			notification.errors.commerce_rif = '<i className="icon-close icons"></i>';
		}

		if (data.email === ''){
			numError++;
			notification.errors.email = '<i className="icon-close icons"></i>';
		}else if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,7})+$/.test( data.email )){
			numError++;
			notification.errors.email = '<i className="icon-close icons"></i>';
		} 

		if (data.lastname === ''){
			numError++;
			notification.errors.lastname = '<i className="icon-close icons"></i>';
		}

		if( numError === 0){
      
			return true;
		}else {
      this.props.addNotification( notification );
			return false
		}
  }
  
	saveData = () => {
		let data = this.state.data;
    if( this.validacionForm() ){
      this.props.registerFromSocial({...this.props.social_data, ...data}, this.state.client_url);
    }
	}

  valEmail = ( value ) => {
    let classError = '';
    if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,7})+$/.test( value )){
        classError = 'input-error';
        this.noActiveBtn();
    } else {
        if(this.state.data.hasOwnProperty('password') ){
          if (this.state.data.password.length > 6){
            this.activeBtn();
          } else {
            this.noActiveBtn();
          }
        }
        classError = '';
    }
    
    if(this.state.errorEmail !== classError){
      this.setState(prevState => ({
          ...prevState,
          errorEmail: classError
      }));
    }
  }

	onChange = (target, validation = null) => {
		let { name, value } = target;
    let val = false;
    
    if (name !== 'domain' && name !== 'rif'){
		  if(!oneOf(["url", "email", "password"], target.type)) {
			  if (target.name !== "company_name") {
				  value = target.value.charAt(0).toUpperCase() + target.value.slice(1)
			  }
		  }
    }
    
		if(validation != null){
			if(value !== ""){
				if( validation.test( value ) ){
					val = true;
				}
			} else {
				val = true;
			}
		} else {
			val = true;
		}
		if(val){
			if(name === 'email'){
        this.valEmail( value );
      } 
      
			if(name === 'password'){
				let classError = '';
        if( value.length < 6 ){
            classError = 'input-error';
            this.noActiveBtn();
        } else {

          if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,7})+$/.test( this.state.data.email) ){
            this.activeBtn();
          } else {
            this.noActiveBtn();
          }

            classError = '';
        }

        this.setState(prevState => ({
            ...prevState,
            errorPassword: classError
        }));
      }
      
      if(name === 'commerce_rif'){
				let classError = '';
                if(!/[VEJPG]-[0-9]{1,8}/.test( value )){
                    classError = 'input-error';
                } else {
                    classError = '';
                }
                this.setState(prevState => ({
                    ...prevState,
                    errorRif: classError
                }));
      }

      this.setState(prevState => ({
				...prevState,
				data: {
					...prevState.data,
					[name]: value
				}
      })); 
		}
  };
  
  validacionLogin = ( noNotifi = null) => {
    let numError = 0;
		let data = this.state.data;
		let notification = {
			message: 'la Información no es correcta para el Ingreso.',
			title: '',
			level: 4,
			errors: {}
		};


		if (data.email === ''){
			numError++;
			notification.errors.email = '<i className="icon-close icons"></i>';
		}else if(!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,7})+$/.test( data.email )){
			numError++;
			notification.errors.email = '<i className="icon-close icons"></i>';
    } 
    
    if(data.hasOwnProperty('password') ){
      if (data.password === ''){
        numError++;
        notification.errors.password = '<i className="icon-close icons"></i>';
      } else  if( data.password.length < 6 ){
        numError++;
        notification.errors.password = '<i className="icon-close icons"></i>';
      }
    }
	

		if( numError === 0){
      
			return true;
		}else {
      if(noNotifi == null){
        this.props.addNotification( notification );
      }
      
			return false
		}
  }

  login = () => {
    if( this.validacionLogin() ){
      this.props.login(this.state.data);
    }
  }

  activeBtn= () => {
    this.setState(prevState => ({
        ...prevState,
        activeBtnLogin: true
    }));
  }

  noActiveBtn= () => {
    this.setState(prevState => ({
        ...prevState,
        activeBtnLogin: false
    }));
  }

  render() {
    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            {
               this.props.valid_registration ?
                <Col md="6">
                  <Card className="p-2 reguister_card">
                    <Form>
                      <h1  className="text-center">Completa el registro</h1>
                      <p className="text-muted mb-3">Creando nuevo usuario:</p>

                      <Label><b>Nombre Administrador:</b> {this.props.social_data.name}</Label> <br/>
                      <Label><b>Apellido Administrador:</b> {this.props.social_data.lastname}</Label> <br/>
                      <Label><b>Email Administrador:</b> {this.props.social_data.email}</Label> <br/>

                      <hr/>

                      <Label>Contraseña de usuario <span className="text-danger">*</span></Label>
                      <InputGroup className="mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                        <i className="fa fa-lock"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="password"
                        maxlength="16"
                        className={this.state.errorPassword}
                        autoComplete="password" name="password" onChange={ (e) => this.onChange(e.target) } value={ this.state.data.password || "" }/>
                      { this.props.has('password') ? 
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                        <i className="icon-close icons text-danger"></i>
                        </InputGroupText>
                      </InputGroupAddon> 
                      : (null) }
                      </InputGroup>


                      <Label>Nombre Comercio {this.props.has('commerce_name')}<span className="text-danger">*</span></Label>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                          <i className="fa fa-industry"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" autoComplete="commerce_name" name="commerce_name" onChange={(e) => this.onChange(e.target, /^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ\s]+$/ )} value={ this.state.data.commerce_name || "" }/>
                        { this.props.has('commerce_name') ?
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                          <i className="icon-close icons text-danger"></i>
                          </InputGroupText>
                        </InputGroupAddon> 
                        : (null) }
                      </InputGroup>

                      <Label>RIF Comercio</Label>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                          <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" className={this.state.errorRif} autoComplete="commerce_rif" name="commerce_rif" onChange={(e) => this.onChange(e.target )} value={ this.state.data.commerce_rif || "" }/>
                        {
                          this.props.has('commerce_rif') ?
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                            <i className="icon-close icons text-danger"></i>
                            </InputGroupText>
                          </InputGroupAddon> 
                          : (null)
                        }
                      </InputGroup>

                      <Label>Estado donde se encuentra el comercio <span className="text-danger">*</span></Label>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                          <i className="icon-globe"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                          <Input type="select"
                          className="register-select"
                          autoComplete="state" name="state" onChange={ (e) => this.citiesByState(e.target.value) } value={ this.state.state || "" } >
                          <option value="">Seleccione...</option>
                          {
                              estados.map( (state, key) => <option key={key} value={state.id_estado}>{state.estado}</option> )
                          }
                          </Input>
                        { this.props.has('state') ? 
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                          <i className="icon-close icons text-danger"></i>
                          </InputGroupText>
                        </InputGroupAddon> 
                        : (null) }
                      </InputGroup>

                      <Label>Ciudad donde se encuentra el comercio <span className="text-danger">*</span></Label>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                          <i className="icon-globe"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                          <Input type="select"
                          className="register-select"
                          autoComplete="city" name="city" onChange={ (e) => this.onChange(e.target) } value={ this.state.data.city || "" } >
                          <option value="">Seleccione...</option>
                          {
                            this.state.cities.map( city => <option value={city}>{city}</option> )
                          }
                          </Input>
                        { this.props.has('city') ? 
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                          <i className="icon-close icons text-danger"></i>
                          </InputGroupText>
                        </InputGroupAddon> 
                        : (null) }
                      </InputGroup>

                      <Button color="primary" className="logo_login" onClick={ () => this.saveData() }>Crear una cuenta</Button>
                    </Form>
                  </Card>
                </Col>
              :
                <Col md="8">
                  <CardGroup>
                    <Card className="p-2 login_card">
                        <Form>
                          <img className="logo_login m-t-50" width="190" src={ logo_login } alt="logo"/>
                          <InputGroup className="mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="fa fa-user"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input type="email"
                                onChange={(e) => this.onChange(e.target)}
                                className={this.state.errorEmail}
                                name="email" placeholder="Correo"/>
                          </InputGroup>
                          <InputGroup className="mb-4">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="icon-lock"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input type="password"
                                onKeyPress={({ key }) => key === "Enter" ? this.login() : null}
                                onChange={(e) => this.onChange(e.target)}
                                className={this.state.errorPassword}
                                name="password" placeholder="Contraseña"/>
                          </InputGroup>
                          <Row>

                            <Col xs="12" className="text-center pull-left col-12 p-20">
                              <Link to="/password-reset" className="c-white p-10 f-12">¿Olvidaste tu contraseña?</Link>
                            </Col>
                            {
                              this.state.activeBtnLogin ? (
                                <Col xs="12">
                                  <Button onClick={(e) => this.login()} color="primary" className="logo_login">ACCEDER</Button>
                                </Col>
                              ) : (
                                <Col xs="12">
                                  <button type="button" className="logo_login btn btn-light">ACCEDER</button>
                                </Col>
                              )
                            }
                            
                            <div className="link-validar text-center pull-left w-100 p-20">
                              <Link to="/register" className="c-white p-10 f-12">¿Eres Comercio? Regístrate!</Link>
                            </div>
                            <Col xs="12" className="text-center">
                              <b className="text_fran"> Inicia sesión con </b>
                            </Col>

                            <Col xs="6" className="text-center p-20">
                              <FacebookLogin
                                textButton = ""
                                appId = {FB_APP_ID}
                                autoLoad={false}
                                fields="name,email"
                                size="small"
                                callback={e => this.handleSocialLogin(e, "facebook")}
                                icon="fa fa-facebook"
                              />
                            </Col>
                            <Col xs="6" className="text-center p-20">
                              <GoogleLogin
                                className="my-2"
                                clientId = {GOOGLE_APP_ID}
                                buttonText=""
                                onSuccess={e => this.handleSocialLogin(e, "google")}
                                onFailure={e => this.handleSocialLoginFailure(e)} 
                              />
                            </Col>
                          </Row>
                        </Form>
                    </Card>
                    <Card className="text-white bg-primary py-5 d-md-down-none" style={{ width: 44 + '%' }}>
                      <CardBody className="text-center">
                        <div>
                          <h2>Regístrate</h2>
                          <p>Krypto Pago te permite el cobro de productos o servicios en criptomonedas de forma fácil, rápida y segura en Venezuela.</p>
                            <Link to="/register">
                              <Button color="primary" className="mt-3" active>
                                ¡Regístrate ahora!
                              </Button>
                            </Link>
                        </div>
                      </CardBody>
                    </Card>
                  </CardGroup>
                </Col>
            }
           
          </Row>
          <Notification/>
          <Preloader />
        </Container>
      </div>
    );
  }
}


const mapStateToProps = (state) => {
	return {
    session: state.clientReducer.session,
    valid_registration: state.clientReducer.valid_registration,
    social_data: state.clientReducer.social_data
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
    login: (user) => dispatch(login(user)),
    registerFromSocial: (data, client_url) => dispatch(registerFromSocial(data, client_url)),
    clear: (field, prefix) => dispatch(clear(field, prefix)),
    has: (field) => dispatch(has(field)),
    get: (field) => dispatch(get(field)),
    socialLogin: user => dispatch(socialLogin(user)),
    addNotification: (field) => dispatch(addNotification(field)),
		closeNotification: () => dispatch(closeNotification()),
		clearStateUser: () => dispatch(clearStateUser())
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);

