export default {
  items: [
    {
      name: 'Balance',
      url: '/dashboard',
      icon: 'icon-speedometer'
    },
    {
      name: 'Tasa',
      url: '/tasa',
      icon: 'crypto-ic-01'
    },
    {
      name: 'Nuevo registro',
      url: '/registrar-pago',
      icon: 'crypto-ic-04'
    },
    {
      name: 'Registros',
      url: '/balance',
      icon: 'crypto-ic-05',
    },
    /* {
      name: 'Billeteras',
      url: '/billeteras',
      icon: 'icon-wallet',
    }, */
    {
      name: 'Usuarios',
      url: '/usuarios',
      icon: 'icon-user',
    },
    {
      name: 'Bancos',
      url: '/bancos',
      icon: 'icon-wallet',
    },
    {
      name: 'Liquidaciones',
      url: '/liquidaciones',
      icon: 'fa fa-money',
    },
  ],
};
