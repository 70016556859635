export default {
  items: [
    {
      name: 'Tasa',
      url: '/tasa',
      icon: 'crypto-ic-01'
    },
    {
      name: 'Nuevo registro',
      url: '/registrar-pago',
      icon: 'crypto-ic-04'
    },
    {
      name: 'Registros',
      url: '/balance',
      icon: 'crypto-ic-05',
    },
    // {
    //   name: 'Transacciones sin validar',
    //   url: '/tx-sin-validar-caja',
    //   icon: 'crypto-ic-05',
    // },
  ],
};
