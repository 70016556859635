import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
	Container,
	CardFooter
} from 'reactstrap';
import { Notification, Preloader } from '../../views/Plugins/';
import {fetchBanks} from '../../store/actions/bankActions';
import { Link } from 'react-router-dom';
import {
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import navigation_supe_user from '../../_nav_super_u';
import navigation from '../../_nav';
import navigation_user_2_no from '../../_nav_u_2_n';
import navigation_user_3 from '../../_nav_u_3';
import navigation_user_4 from '../../_nav_u_4';
// routes config
import routes from '../../routes';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';

class DefaultLayout extends Component {

  constructor(props) {
    super(props);
    if(this.props.session.type === 2) {
      this.props.fetchBanks();
    };
  }

  render() {
    let nav = '';

    document.title = 'KryptoPago ' + this.props.location.pathname.replace('/', '')

    switch (this.props.session.type) {
      case 1:
        nav = (
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <AppSidebarNav navConfig={navigation_supe_user} {...this.props} />
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
        );
      break;

      case 2:
        if( this.props.commerce.status === 2 ){
          nav = (
            <AppSidebar fixed display="lg">
              <AppSidebarHeader />
              <AppSidebarForm />
              <AppSidebarNav navConfig={navigation} {...this.props} />
              <AppSidebarFooter />
              <AppSidebarMinimizer />
            </AppSidebar>
          );
        } else {
          nav = (
            <AppSidebar fixed display="lg">
              <AppSidebarHeader />
              <AppSidebarForm />
              <AppSidebarNav navConfig={navigation_user_2_no} {...this.props} />
              <AppSidebarFooter />
              <AppSidebarMinimizer />
            </AppSidebar>
          );
        }

      break;
      
      case 4:
        nav = (
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <AppSidebarNav navConfig={navigation_user_4} {...this.props} />
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
        );
        break;

      default:
          //document.body.setAttribute("class", "userType-3");
          nav = (
            <AppSidebar fixed display="lg">
              <AppSidebarHeader />
              <AppSidebarForm />
              <AppSidebarNav navConfig={navigation_user_3} {...this.props} />
              <AppSidebarFooter />
              <AppSidebarMinimizer />
            </AppSidebar>
          );
        break;
    }

    return (
      <div className="app">
        {
          this.props.banks?
          (
            <Link to="/bancos" className="valid_trans dialog-header noBanck"> 
							<span className="dialog-title">
								<span className="text_header_pay_registro"> Debe seleccionar los bancos con que trabaja </span>
							</span>
						</Link>
          )
          :
          ('')
        }
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
        <div className="app-body">
         { nav }
          <main className="main">
            <Container fluid>
              <Switch>
                {routes.map((route, idx) => {
                    return route.component ? (
                      <Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                        <route.component {...props} />
                      )} />
                      )
                      : (null);
                  },
                )}
                <Redirect from="/" to="/404" />
              </Switch>
            </Container>
          </main>
        </div>
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
        <Notification />
        <Preloader />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
	return {
    session: state.clientReducer.session,
    banks: state.bankReducer.alertBanc,
    commerce: state.commerceReducer.commerce,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
    fetchBanks: () => dispatch(fetchBanks()),
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);