export default {
  items: [
    {
      name: 'Balance',
      url: '/dashboard',
      icon: 'icon-speedometer'
    },
    {
      name: 'Comercios',
      url: '/comercios',
      icon: 'fa fa-industry',
    },
    /* {
      name: 'Billeteras',
      url: '/billeteras',
      icon: 'icon-wallet',
    }, */
    {
      name: 'Usuarios',
      url: '/usuarios',
      icon: 'icon-user',
    },
    {
      name: 'Bancos',
      url: '/bancos',
      icon: 'icon-wallet',
    },
    {
      name: 'Liquidaciones',
      url: '/liquidaciones',
      icon: 'fa fa-money',
    },
    {
      name: 'Cuentas por verificar',
      url: '/admin-cuentas-bancarias',
      icon: 'icon-check',
    },
  ],
};
