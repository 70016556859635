import React, { Component } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

class TemplateDropdown extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false,
    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }
  componentDidMount (){
    if(this.props.open === "true"){
      this.setState({
        dropdownOpen: true,
      });
    }
  }

  render() {
    return (
          <Dropdown className="template_dropdown w_100" isOpen={this.state.dropdownOpen} toggle={() => {this.toggle();}}>
            <DropdownToggle 
            tag="span"
            onClick={this.toggle}
            data-toggle="dropdown"
            className="btn btn-dropdown w_100"
            aria-expanded={this.state.dropdownOpen}
            >
              {this.props.name}
              <i className="icon-options icons d-block"></i>
            </DropdownToggle>
            <DropdownMenu>
                {this.props.children}
            </DropdownMenu>
          </Dropdown>
    );
  }
}

export default TemplateDropdown;
