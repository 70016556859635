import { URL } from '../../config.js';
import axios from 'axios';
import { logout } from './userActions';
var moment = require('moment');

/**
 * @description logs in the user
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any> | never | never>}
 */

const tasa = ( data ) => {
    return (dispatch) => {
        dispatch({type: "WAIT_FOR_RESPONSE"});
        let Url_Tasa = URL + '/tasa/0';
        if(data === 2){
            Url_Tasa = URL + '/tasa/1';
        }
        return axios.get( Url_Tasa ).then( (res) => {
            if( data === 2 ){
                let aux = res.data.avg.avg_24h * res.data.dash;
                res.data.avg.avg_24h = aux;
            }
            dispatch({
                type: 'FETCH_TASA',
                info_tasa: res.data
            });
            
            dispatch({type: "WAIT_FOR_RESPONSE"});
        }).catch( (err) => {
            if( (err.response.status === 401) && (err.response.data.error === 'Token has expired')){
                dispatch( logout() );
            }
            dispatch({type: "WAIT_FOR_RESPONSE"});
        });
    }
};


const avg_week = () => {
    return (dispatch) => {
        return axios.get(URL + '/avg-week').then( (res) => {

            let graf = {};
            graf.cant_graf = [];
            graf.date_graf = [];

            
            res.data.data.forEach(function(x) {
                graf.date_graf.push( moment(x.ini).format("DD/MM/YY") +' - ' + moment(x.fin).format("DD/MM/YY") );
                graf.cant_graf.push( x.val );
            });

            dispatch({
                type: 'FETCH_GRAF',
                data: graf
            });

        }).catch( (err) => {
            if( (err.response.status === 401) && (err.response.data.error === 'Token has expired')){
                dispatch( logout() );
            }
            let graf = {};
            graf.cant_graf = [];
            graf.date_graf = [];
            dispatch({
                type: 'FETCH_GRAF',
                data: graf
            });
        });
    }
};

const avg_dashboard = ( ) => {
    return (dispatch) => {
        return axios.get(URL + '/grafVolumenBtc').then( (res) => {
            let graf = {};
            graf.cant_graf = [];
            graf.date_graf = [];

            res.data.transaction.forEach(function(x) {
                graf.date_graf.push( moment(x.created_at).format("DD/MM/YY") );
                graf.cant_graf.push( x.amount_btc );
            });

            dispatch({
                type: 'FETCH_GRAF',
                data: graf
            });
            
        }).catch( (err) => {
            if( (err.response.status === 401) && (err.response.data.error === 'Token has expired')){
                dispatch( logout() );
            }
            let graf = {};
            graf.cant_graf = [];
            graf.date_graf = [];
            dispatch({
                type: 'FETCH_GRAF',
                data: graf
            });
        });
    }
};

const avg_dashboard_week = ( ) => {
    return (dispatch) => {
        return axios.get(URL + '/grafVolumenBtcWeek').then( (res) => {
            let graf = {};
            graf.cant_graf = [];
            graf.date_graf = [];

            res.data.transaction.forEach(function(x) {
                graf.date_graf.push( moment(x.created_at).format("DD/MM/YY") );
                graf.cant_graf.push( x.amount_btc );
            });

            dispatch({
                type: 'FETCH_GRAF',
                data: graf
            });
            
        }).catch( (err) => {
            if( (err.response.status === 401) && (err.response.data.error === 'Token has expired')){
                dispatch( logout() );
            }
            let graf = {};
            graf.cant_graf = [];
            graf.date_graf = [];
            dispatch({
                type: 'FETCH_GRAF',
                data: graf
            });
        });
    }
};

const grafTasaMonth = ( ) => {
    return (dispatch) => {
        return axios.get(URL + '/grafTasaMonth').then( (res) => {
            let graf = {};
            graf.cant_graf = [];
            graf.date_graf = [];

            
            res.data.data.dates.forEach(function(x) {
                graf.date_graf.push( moment(x.created_at).format("DD/MM/YY") );
            });

            graf.cant_graf = res.data.data.val;

            dispatch({
                type: 'FETCH_GRAF',
                data: graf
            });
            
        }).catch( (err) => {
            if( (err.response.status === 401) && (err.response.data.error === 'Token has expired')){
                dispatch( logout() );
            }
            let graf = {};
            graf.cant_graf = [];
            graf.date_graf = [];
            dispatch({
                type: 'FETCH_GRAF',
                data: graf
            });
        });
    }
};

const grafTasaHour = ( ) => {
    return (dispatch) => {
        return axios.get(URL + '/grafTasaHour').then( (res) => {
            let graf = {};
            graf.cant_graf = [];
            graf.date_graf = [];

            res.data.data[0].forEach(function(x) {
                graf.date_graf.push( moment(x).format("DD-MM/HH:mm") );
            });

            graf.cant_graf = res.data.data[1];
            dispatch({
                type: 'FETCH_GRAF',
                data: graf
            });
            
        }).catch( (err) => {
            if( (err.response.status === 401) && (err.response.data.error === 'Token has expired')){
                dispatch( logout() );
            }
            let graf = {};
            graf.cant_graf = [];
            graf.date_graf = [];
            dispatch({
                type: 'FETCH_GRAF',
                data: graf
            });
        });
    }
};

/*
 *      unificacion de cargas en tasas
 *
 *      this.props.tasa(1);
 *      this.props.grafTasaMonth();
 */

function tasaUnification () {
    return axios.get(URL + '/tasa/0');
}

function grafTasaMonthUnification () {
    return axios.get(URL + '/grafTasaMonth');
}

const initTasa = ( ) => {
    return (dispatch) => {
       
        dispatch({type: "WAIT_FOR_RESPONSE"});
        return  axios.all( [tasaUnification(), grafTasaMonthUnification ()] ).then( axios.spread(function (
            res_tasaUnification,
            res_grafTasaMonthUnification,
        ) {
            dispatch({
                type: 'FETCH_TASA',
                info_tasa: res_tasaUnification.data
            });

            let graf = {};
            graf.cant_graf = [];
            graf.date_graf = [];

            
            res_grafTasaMonthUnification.data.data.dates.forEach(function(x) {
                graf.date_graf.push( moment(x.created_at).format("DD/MM/YY") );
            });

            graf.cant_graf = res_grafTasaMonthUnification.data.data.val;

            dispatch({
                type: 'FETCH_GRAF',
                data: graf
            });

            dispatch({type: "WAIT_FOR_RESPONSE"});
        })).catch( (err) => {
            if( (err.response.status === 401) && (err.response.data.error === 'Token has expired')){
                dispatch( logout() );
            }
            // dispatch({
            //     type: 'NOTIFICATION',
            //     notification: NotificationHandler(err.response)
            // });
        });
    }
}

/*
 *      unificacion de cargas en Dashboard
 *
 *      this.props.getDashboard();
 *      this.props.avg_dashboard();
 */

function getDashboardUnification () {
    return axios.get(URL + '/dashboard');
}

function avg_dashboardUnification () {
    return axios.get(URL + '/grafVolumenBtc');
}

const initDashboard = ( ) => {
    return (dispatch) => {
        dispatch({type: "WAIT_FOR_RESPONSE"});
        return  axios.all( [getDashboardUnification(), avg_dashboardUnification ()] ).then( axios.spread(function (
            res_getDashboardUnification,
            res_avg_dashboardUnification,
        ) {
            let graf = {};
            graf.cant_graf = [];
            graf.date_graf = [];

            res_avg_dashboardUnification.data.transaction.forEach(function(x) {
                graf.date_graf.push( moment(x.created_at).format("DD/MM/YY") );
                graf.cant_graf.push( x.amount_btc );
            });

            dispatch({
                type: 'FETCH_GRAF',
                data: graf
            });


            
            dispatch({
                type: 'FETCH_DASHBOARD',
                dashboard: res_getDashboardUnification.data
            });

            dispatch({type: "WAIT_FOR_RESPONSE"});
        })).catch( (err) => {
            if( (err.response.status === 401) && (err.response.data.error === 'Token has expired')){
                dispatch( logout() );
            }
            // dispatch({
            //     type: 'NOTIFICATION',
            //     notification: NotificationHandler(err.response)
            // });
        });
    }
}
export {
    tasa,
    avg_week,
    avg_dashboard,
    grafTasaMonth,
    grafTasaHour,
    avg_dashboard_week,
    initTasa,
    initDashboard
};